import React from "react";
import styled from "styled-components";
import { useMedia } from "../../hooks/useMedia";
import { assetsPath } from "../../services/tools";

const Color = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  background: ${(props) => "#" + (props.line.color || "888")};
  color: white;
  font-size: 1.4em;
  font-weight: bold;
  flex: 1;
  justify-self: ${(props) => props.mode.includes("tramway") && "center"};
  border-radius: ${(props) => props.mode.includes("tramway") && "50%"};
  width: ${(props) => props.mode.includes("tramway") && "25px"};
  max-width: ${(props) => props.mode.includes("tramway") && "25px"};
  margin-right: ${(props) => props.mode.includes("tramway") && "10px"};

  &:hover {
    opacity: 0.8;
  }
`;

const Image = styled.div.attrs((props) => ({
  style: {
    backgroundImage: "url(" + assetsPath(`/assets/images/lines/${encodeURI(props.line.code)}.svg`) + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: !props.mode.includes("bus") && props.line.cat && (props.isDesktop ? "42px" : "34px"),
    height: props.isDesktop ? "42px" : "34px",

    marginRight: props.mode.includes("tramway") && props.grid && "10px",

    flex: !props.mode.includes("tramway") && 1,
  },
}))`
  &:hover {
    opacity: 0.8;
  }
`;

export const UILine = (props) => {
  const isDesktop = useMedia();

  return props.image ? (
    <Image {...props} isDesktop={isDesktop} className={"lc-uiline lc-uiline-image " + props.mode} />
  ) : (
    <Color {...props} isDesktop={isDesktop} className={"lc-uiline"}>
      {props.line.code}
    </Color>
  );
};

UILine.defaultProps = {
  line: null,
  mode: "",
};
