import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { assetsPath, handleKeyPress, translate } from "../../services/tools";
import { actionSetCalendarDate, actionSetCalendarShow } from "./../../actions/app";
import Calendar from "react-calendar";
import { dateToLibelle, formatDate } from "../../utils/tools";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useMedia } from "../../hooks/useMedia";
// import "../../scss/_calendar.css";

const Root = styled.div`
  display: inline-block;
`;

const CalendarButton = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  margin: ${(props) => (props.margin || 0) + "px"};
  font-size: ${(props) => (props.margin ? "0.875em" : "1em")};
  padding: ${(props) => (props.margin ? "8px" : "0 8px")};
  align-items: center;
  border-radius: 5px;
  border: ${(props) => (props.border ? "2px solid rgba(0, 0, 0, 0.08)" : "none")};
  img {
    width: 25px;
    padding-right: 10px;
  }
`;

const UICalendar = (props) => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const { minDate, maxDate, defaultDateTime, onChangeDate, button, buttonBorder, followInput, margin } = props;
  const calendar = useSelector((state) => state.app.calendar);
  const language = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const refCalendar = useRef();
  const size = useWindowSize();
  const isDesktop = useMedia();

  useClickOutside(refCalendar, () => {
    if (calendar.show) {
      dispatch(actionSetCalendarShow(false));
    }
  });

  const handleOnChangeDate = (date) => {
    dispatch(actionSetCalendarDate(date));

    if (onChangeDate !== undefined) {
      onChangeDate(date);
    }
  };

  useEffect(() => {
    if (followInput && isDesktop) {
      document.querySelector(".lc-calendar").style.top = "-9999px";
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShow(calendar.show);

    if (followInput) {
      function updateCalendarPosition() {
        const board = document.querySelector(".lc-board").getBoundingClientRect();
        const boundingBoxButton = document.querySelector(".lc-calendar-button").getBoundingClientRect();
        const boundingBoxCalendar = refCalendar.current.getBoundingClientRect();
        let top = boundingBoxButton.y;

        // +/- 20 : base calc are done on the button y position but we don't want our calendar stick to it
        if (boundingBoxButton.y + boundingBoxCalendar.height > size.height) {
          top = size.height - boundingBoxCalendar.height - 20;
        } else {
          top += 20;
        }

        // remove override top on lc-board if different than 20 (default value)
        top -= board.top - 20;

        refCalendar.current.style.top = top + "px";
      }

      const scrollElement = document.querySelector(".lc-scroll");

      if (calendar.show) {
        if (button && scrollElement) {
          setTimeout(updateCalendarPosition);
          scrollElement.addEventListener("scroll", updateCalendarPosition);
        }
      } else {
        scrollElement && scrollElement.removeEventListener("scroll", updateCalendarPosition);
      }
    }

    // eslint-disable-next-line
  }, [calendar.show, size]);

  useEffect(() => {
    setDate(defaultDateTime);
    // eslint-disable-next-line
  }, [defaultDateTime]);

  useEffect(() => {
    setDate(calendar.date);
    dispatch(actionSetCalendarShow(false));

    // eslint-disable-next-line
  }, [calendar.date]);

  useEffect(() => {
    const now = formatDate(new Date(), "ymd");
    let displayTodayButton = true;

    if (minDate !== undefined && now < formatDate(minDate, "ymd")) {
      displayTodayButton = false;
    }

    if (maxDate !== undefined && formatDate(maxDate, "ymd") > now) {
      displayTodayButton = false;
    }

    if (refCalendar && refCalendar.current && displayTodayButton) {
      const today = document.createElement("div");

      today.classList.add("lc-calendar-today-button");
      today.addEventListener("click", () => {
        handleOnChangeDate(new Date());
      });
      today.addEventListener("keypress", (e) =>
        handleKeyPress(e, () => {
          handleOnChangeDate(new Date());
        })
      );

      today.innerHTML = translate("calendar-today-word");
      today.setAttribute("tabIndex", "0");
      today.setAttribute("role", "button");

      refCalendar.current.appendChild(today);
    }

    // eslint-disable-next-line
  }, [refCalendar]);

  const UICalendarButton = (props) => {
    return (
      <Root>
        <CalendarButton
          className="lc-calendar-button"
          border={props.border}
          margin={margin}
          onClick={(e) => {
            dispatch(actionSetCalendarShow(!show));
          }}
          onKeyPress={(e) => handleKeyPress(e, () => dispatch(actionSetCalendarShow(!show)))}
          tabIndex="0"
          role="button"
          aria-label={translate("aria-route-calculation-date", false, {
            key: "date",
            value: dateToLibelle(calendar.date, language, "full-with-year"),
          })}
        >
          <img src={assetsPath("/assets/images/calendar.svg")} alt={translate("calendar-alt", false)} />
          {dateToLibelle(calendar.date, language, "full-with-year")}
        </CalendarButton>
      </Root>
    );
  };

  return (
    <>
      {!button ? null : <UICalendarButton border={buttonBorder} />}
      <Calendar
        value={date}
        onChange={handleOnChangeDate}
        inputRef={refCalendar}
        className={"lc-calendar" + (show === false ? " lc-hidden" : " active")}
        tileClassName="lc-calendar-tile"
        locale={language}
        minDate={minDate}
        maxDate={maxDate}
        navigationAriaLabel={translate("aria-calendar-go-up", false)}
        nextAriaLabel={translate("aria-calendar-next", false)}
        next2AriaLabel={translate("aria-calendar-jump-forwards", false)}
        prevAriaLabel={translate("aria-calendar-previous", false)}
        prev2AriaLabel={translate("aria-calendar-jump-backwards", false)}
      />
    </>
  );
};

export default UICalendar;
