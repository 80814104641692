import Plausible from "plausible-tracker";
import TagManager from "react-gtm-module";
import { envVarToBool, debug } from "./services/tools";
import { appStore } from "./store";

const { REACT_APP_GTM, REACT_APP_PLAUSIBLE, REACT_APP_GTM_LIB } = process.env;
let plausible = null;

export const initGTM = () => {
  if (REACT_APP_GTM) {
    const tagManagerArgs = {
      gtmId: REACT_APP_GTM,
    };

    TagManager.initialize(tagManagerArgs);
  }
};

export const initPlausible = () => {
  if (REACT_APP_PLAUSIBLE) {
    plausible = Plausible({
      domain: REACT_APP_PLAUSIBLE,
      apiHost: "https://analytics.lc.tools",
      hashMode: true,
    });
  }
};

export const UpdatePageView = () => {
  if (plausible) {
    plausible.trackPageview();
  }
};

export const updateDataLayer = (dataLayer) => {
  const hasAuthorizedCookies = appStore.getState().app.hasAuthorizedCookies;

  debug({ message: `Update datalayer :`, data: dataLayer }, "info", "DataLayer");

  if (REACT_APP_GTM && hasAuthorizedCookies) {
    const tagManagerArgs = {
      dataLayer,
    };

    TagManager.dataLayer(tagManagerArgs);
  } else if (envVarToBool(REACT_APP_GTM_LIB)) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
  }
};
