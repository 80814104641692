import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { humanReadableOpeningHours, translate, assetsPath, handleKeyPress } from "../../../services/tools";
import { actionDecrementStationIndex, actionIncrementStationIndex } from "../../../actions/board";
import Tippy from "@tippy.js/react";
import { message } from "../../../services/message";
import { formatDate } from "../../../utils/tools";
import BikeInterface from "../../../interfaces/BikeInterface";
import {
  PlaceInfo,
  PlaceInfoFlex,
  PlaceInfoOpeningHours,
  PlaceInfoTitle,
  PlaceInfoContent,
  PlaceRealtimeInfo,
  RealtimeImage,
  PRPlaceInfo,
  VCubPlaceInfos,
  BikePrices,
  PictoBike,
  PlaceCars,
  PlaceCar,
  CustomText,
  StationSchedulesHeader,
  StationSchedulesContent,
  StationSchedulesEntry,
  StationSchedulesEntryMode,
  StationPagination,
  StationPaginationNext,
  StationPaginationPrevious,
  StationSchedules,
  PlaceInfoInline,
} from "./styled";

const { REACT_APP_PROJECT } = process.env;

// "poi_type:amenity:parcs_relais"
export const UIParcsRelais = (props) => {
  const { place } = props;

  if (!place.capacity) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate("total-places")}</PlaceInfoTitle>
        &nbsp;<PlaceInfoContent>{place.capacity}</PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  );
};

// "poi_type:stations"
export const UIStations = (props) => {
  const { place, displayon } = props;
  const dispatch = useDispatch();
  const { currentStationDepartureIndex, currentStationArrivalIndex } = useSelector((state) => state.board);

  if (!place || displayon === "map") {
    return null;
  }

  return (
    <PlaceInfo>
      <StationSchedules>
        <StationSchedulesHeader>
          <div>{translate("sncf-stations-departure")}</div>
        </StationSchedulesHeader>
        <StationSchedulesContent className={place.stand && place.stand.departures.length ? " lc-not-empty" : ""}>
          {place.stand && place.stand.departures.length
            ? place.stand.departures
                .filter(
                  (_, index) =>
                    index > currentStationDepartureIndex * 3 - 1 && index <= currentStationDepartureIndex * 3 + 2
                )
                .map((departure, index) => (
                  <StationSchedulesEntry
                    key={index}
                    className="lc-station-schedules-entry"
                    tabIndex="0"
                    aria-label={translate(
                      "aria-station-label",
                      false,
                      { key: "type", value: translate("sncf-stations-departure", false) },
                      { key: "mode", value: departure.mode },
                      { key: "time", value: departure.basetime },
                      { key: "direction", value: departure.direction }
                    )}
                  >
                    <div>
                      {departure.realtime ? (
                        <span>
                          {departure.time} <del>{departure.basetime}</del>
                        </span>
                      ) : (
                        departure.basetime
                      )}
                    </div>
                    <div className="lc-station-schedules-entryDirection">
                      {departure.direction}
                      <StationSchedulesEntryMode className="lc-station-schedules-entry-mode">
                        {departure.mode}
                      </StationSchedulesEntryMode>
                    </div>
                  </StationSchedulesEntry>
                ))
            : translate("no-stations-departures")}
        </StationSchedulesContent>
        {place.stand && place.stand.departures.length > 3 && (
          <StationPagination>
            <StationPaginationPrevious
              onClick={(e) => {
                e.stopPropagation();
                currentStationDepartureIndex > 0 && dispatch(actionDecrementStationIndex("departure"));
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  currentStationDepartureIndex > 0 && dispatch(actionDecrementStationIndex("departure"));
                })
              }
              role="button"
              tabIndex="0"
              aria-label={translate("aria-station-previous-departure", false)}
            >
              <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} aria-hidden="true" />
            </StationPaginationPrevious>
            <StationPaginationNext
              onClick={(e) => {
                e.stopPropagation();
                currentStationDepartureIndex < 6 && dispatch(actionIncrementStationIndex("departure"));
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  currentStationDepartureIndex < 6 && dispatch(actionIncrementStationIndex("departure"));
                })
              }
              role="button"
              tabIndex="0"
              aria-label={translate("aria-station-next-departure", false)}
            >
              <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} aria-hidden="true" />
            </StationPaginationNext>
          </StationPagination>
        )}
      </StationSchedules>
      <StationSchedules>
        <StationSchedulesHeader>
          <div>{translate("sncf-stations-arrival")}</div>
        </StationSchedulesHeader>
        <StationSchedulesContent className={place.stand && place.stand.arrivals.length ? " lc-not-empty" : ""}>
          {place.stand && place.stand.arrivals.length
            ? place.stand.arrivals
                .filter(
                  (_, index) =>
                    index > currentStationArrivalIndex * 3 - 1 && index <= currentStationArrivalIndex * 3 + 2
                )
                .map((arrival, index) => (
                  <StationSchedulesEntry
                    key={index}
                    tabIndex="0"
                    aria-label={translate(
                      "aria-station-label",
                      false,
                      { key: "type", value: translate("sncf-stations-arrival", false) },
                      { key: "mode", value: arrival.mode },
                      { key: "time", value: arrival.basetime },
                      { key: "direction", value: arrival.direction }
                    )}
                  >
                    <div>
                      {arrival.realtime ? (
                        <span>
                          {arrival.time} <del>{arrival.basetime}</del>
                        </span>
                      ) : (
                        arrival.basetime
                      )}
                    </div>
                    <div className="lc-station-schedules-entryDirection">
                      {arrival.direction}
                      <br />
                      <StationSchedulesEntryMode className="lc-station-schedules-entry-mode">
                        {arrival.mode}
                      </StationSchedulesEntryMode>
                    </div>
                  </StationSchedulesEntry>
                ))
            : translate("no-stations-arrivals")}
        </StationSchedulesContent>
        {place.stand && place.stand.arrivals.length > 3 && (
          <StationPagination>
            <StationPaginationPrevious
              onClick={(e) => {
                e.stopPropagation();
                currentStationArrivalIndex > 0 && dispatch(actionDecrementStationIndex("arrival"));
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  currentStationArrivalIndex > 0 && dispatch(actionDecrementStationIndex("arrival"));
                })
              }
              role="button"
              tabIndex="0"
              aria-label={translate("aria-station-previous-arrival", false)}
            >
              <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} aria-hidden="true" />
            </StationPaginationPrevious>
            <StationPaginationNext
              onClick={(e) => {
                e.stopPropagation();
                currentStationArrivalIndex < 6 && dispatch(actionIncrementStationIndex("arrival"));
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  currentStationArrivalIndex < 6 && dispatch(actionIncrementStationIndex("arrival"));
                })
              }
              role="button"
              tabIndex="0"
              aria-label={translate("aria-station-next-arrival", false)}
            >
              <img src={assetsPath("/assets/images/v.svg")} alt={translate("collapse-arrow")} aria-hidden="true" />
            </StationPaginationNext>
          </StationPagination>
        )}
      </StationSchedules>
    </PlaceInfo>
  );
};

// "poi_type:amenity:bicycle_parking"
export const UIBicycleParking = (props) => {
  const { place } = props;

  return (
    <PlaceInfoFlex tabIndex="0" aria-label={translate("total-places", false) + " " + place.capacity}>
      <PlaceInfoTitle>{translate("total-places")}</PlaceInfoTitle>
      &nbsp;<PlaceInfoContent>{place.capacity}</PlaceInfoContent>
    </PlaceInfoFlex>
  );
};

// "poi_type:amenity:bicycle_rental"
export const UIBicycleRental = (props) => {
  const { place, options } = props;

  if (place.stand) {
    const bikesAvailable =
      place.stand && Object.keys(place.stand).length > 0
        ? place.stand.available_bikes
        : place.availablePlaces
        ? place.availablePlaces
        : place.availableBikes || translate("no-data");

    return (
      <>
        {place.is_available === true ? (
          <PlaceInfo
            tabIndex="0"
            aria-label={
              translate("aria-bikes-available", false, {
                key: "bikes",
                value: bikesAvailable,
              }) +
              ". " +
              translate("aria-bikes-available", false, {
                key: "seats",
                value: bikesAvailable,
              })
            }
          >
            <PlaceInfoTitle>{translate("nb-bikes-available")}&nbsp;</PlaceInfoTitle>
            <PlaceInfoContent>
              {bikesAvailable}
              <PictoBike
                src={assetsPath("/assets/images/modes/bss.svg")}
                alt={translate("bss-bikes-available")}
                aria-hidden="true"
              />
            </PlaceInfoContent>
          </PlaceInfo>
        ) : (
          <PlaceInfo>
            <PlaceInfoTitle tabIndex="0" aria-label={translate("bss-closed", false)}>
              {translate("bss-closed")}
            </PlaceInfoTitle>
          </PlaceInfo>
        )}
      </>
    );
  } else if (place instanceof BikeInterface) {
    return (
      <>
        {place.is_available !== undefined && place.is_available === false && (
          <PlaceInfo>
            <PlaceInfoTitle>{translate("bike-station-unavailable")}</PlaceInfoTitle>
          </PlaceInfo>
        )}
        {place.availablePlaces !== undefined && (
          <PlaceInfo>
            <PlaceInfoFlex data-realtime-seats>
              <PlaceInfoTitle>{translate("nb-bikes-available")}&nbsp;</PlaceInfoTitle>
              <PlaceInfoContent>
                {place.availablePlaces || translate("no-data")}
                <PictoBike src={assetsPath("/assets/images/modes/bss.svg")} alt={translate("bss-bikes-available")} />
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={translate("realtime-gif-title")}
                >
                  <RealtimeImage
                    src={assetsPath("/assets/images/realtime.gif")}
                    alt={translate("realtime-gif-alt")}
                    aria-hidden="true"
                    marginLeft={true}
                    width={"15px"}
                    height={"15px"}
                  />
                </Tippy>
              </PlaceInfoContent>
            </PlaceInfoFlex>
          </PlaceInfo>
        )}
        {place.availableBikes !== undefined && (
          <PlaceInfo>
            <PlaceInfoFlex data-realtime-seats>
              <PlaceInfoTitle>{translate("nb-bikes-available")}&nbsp;</PlaceInfoTitle>
              <PlaceInfoContent>
                {place.availableBikes || translate("no-data")}
                <PictoBike src={assetsPath("/assets/images/modes/bss.svg")} alt={translate("bss-bikes-available")} />
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={translate("realtime-gif-title")}
                >
                  <RealtimeImage
                    src={assetsPath("/assets/images/realtime.gif")}
                    alt={translate("realtime-gif-alt")}
                    aria-hidden="true"
                    marginLeft={true}
                    width={"15px"}
                    height={"15px"}
                  />
                </Tippy>
              </PlaceInfoContent>
            </PlaceInfoFlex>
          </PlaceInfo>
        )}
        {options?.dontShowCapacity !== true && place.capacity !== undefined && (
          <PlaceInfo>
            <PlaceInfoFlex>
              <PlaceInfoTitle>{translate("nb-seats-available")}&nbsp;</PlaceInfoTitle>
              <PlaceInfoContent>
                {place.capacity || translate("no-data")}
                <PictoBike src={assetsPath("/assets/images/bss-seat.svg")} alt={translate("available-places")} />
              </PlaceInfoContent>
            </PlaceInfoFlex>
          </PlaceInfo>
        )}
        {place.prices !== undefined && (
          <PlaceInfo>
            <PlaceInfoTitle>{translate("prices")}</PlaceInfoTitle>
            <PlaceInfoContent>
              <BikePrices>
                {Object.keys(place.prices).map((time) => {
                  let min = parseInt(time);
                  let hours = 0;

                  if (time > 59) {
                    hours = parseInt(Math.floor(min / 60));
                    min = min % 60;
                  }

                  if (min < 10) {
                    min = "0" + min;
                  }

                  return (
                    <li key={"price-" + time}>
                      {hours > 0 ? `${hours}h${min}` : `${min}min`} : {place.prices[time]}€
                    </li>
                  );
                })}
              </BikePrices>
            </PlaceInfoContent>
          </PlaceInfo>
        )}
        {place.price_url !== undefined && (
          <PlaceInfo>
            <PlaceInfoTitle>{translate("see-prices")}</PlaceInfoTitle>
            <PlaceInfoContent>
              <a href={place.price_url} target="_blank" rel="noopener noreferrer">
                {place.price_url}
              </a>
            </PlaceInfoContent>
          </PlaceInfo>
        )}
      </>
    );
  } else {
    return null;
  }
};

// "poi_type:amenity:park_ride"
export const UIParkRide = (props) => {
  const { place } = props;

  if (!place.stand) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate("total-places")}&nbsp;</PlaceInfoTitle>
        <PlaceInfoContent>
          {place.stand.total_places} &nbsp;
          {place.stand.occupied_PRM ? "- " + place.stand.occupied_PRM + " " + translate("disabled-spaces") : ""}
        </PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  );
};

// "poi_type:amenity:parking"
export const UIParking = (props) => {
  const { place } = props;

  if (!place.stand) {
    return null;
  }

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={
        place.capacity +
        " " +
        translate("total-places", false) +
        ". " +
        (place.stand.occupied_PRM ? place.stand.occupied_PRM + " " + translate("disabled-spaces", false) + ". " : "") +
        (place.stand.available && place.stand.available !== 0
          ? translate("aria-available-places", false, {
              key: "count",
              value: place.stand.available,
            })
          : place.stand.available === 0
          ? translate("no-available-places", false)
          : translate("no-informations-for-available-places", false))
      }
    >
      {place.stand.available && place.stand.available !== 0 ? (
        <PlaceRealtimeInfo data-realtime-seats>
          {place.stand.available} {translate("available-places")}&nbsp;
          <Tippy
            theme={"latitude"}
            touch={["hold", 500]}
            placement={"right"}
            boundary="window"
            content={translate("realtime-gif-title")}
          >
            <RealtimeImage
              src={assetsPath("/assets/images/realtime.gif")}
              alt={translate("realtime-gif-alt")}
              aria-hidden="true"
            />
          </Tippy>
        </PlaceRealtimeInfo>
      ) : place.stand.available === 0 ? (
        <PlaceRealtimeInfo data-realtime-seats>{translate("no-available-places")}</PlaceRealtimeInfo>
      ) : (
        <PlaceRealtimeInfo data-realtime-seats>{translate("no-informations-for-available-places")}</PlaceRealtimeInfo>
      )}
      {place.capacity && (
        <PlaceInfoFlex>
          <PlaceInfoTitle>{translate("total-places")}</PlaceInfoTitle>
          &nbsp;<PlaceInfoContent>{place.capacity}</PlaceInfoContent>
        </PlaceInfoFlex>
      )}
      {place.stand?.occupied_PRM && (
        <PlaceInfoFlex>
          <PlaceInfoTitle>{translate("disabled-spaces")} :</PlaceInfoTitle>
          &nbsp;<PlaceInfoContent>{place.stand.occupied_PRM}</PlaceInfoContent>
        </PlaceInfoFlex>
      )}
    </PlaceInfo>
  );
};

// "poi_type:p+r"
export const UIPR = (props) => {
  const { place, options, language, displayon } = props;
  const prDetails = options?.thematics?.find((t) => t.name === "p+r")?.details;
  const prMoreInfos = () => message({ clicked: "p+r-more-infos", id: place.id });
  const updatedAt = place.updatedAt ? new Date(place.updatedAt.replace(" ", "T")) : null;
  const now = new Date();

  const formattedUpdatedAt = updatedAt
    ? updatedAt?.getDate() === now.getDate() &&
      updatedAt?.getMonth() === now.getMonth() &&
      updatedAt?.getFullYear() === now.getFullYear()
      ? `${translate("data-updated-at")} ${formatDate(updatedAt, "h:m", language)}`
      : `${translate("data-updated-on")} ${formatDate(updatedAt, "d/m/y h:m", language)}`
    : null;

  return (
    <PRPlaceInfo displayon={displayon}>
      <address className="lc-pr-address">{place.info}</address>

      {displayon === "map" && (
        <>
          <div className="lc-pr-slots-container">
            {place.color === "closed" ? (
              <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                {translate("closed-pr")}
              </div>
            ) : place.stand.available && place.stand.available !== 0 ? (
              <>
                <div className="lc-pr-realtime-seats" data-realtime-seats>
                  {place.stand.available}
                  {place.stand.available_electric_cars === null && place.stand.available_prm === null && (
                    <span className="lc-slots">
                      {place.stand.available <= 1 ? translate("slot") : translate("slots")}
                    </span>
                  )}
                  {/* {place.stand.available <= 1 ? translate("slot") : translate("slots")} */}
                </div>
              </>
            ) : place.stand.available === 0 ? (
              <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                {translate("no-available-places")}
              </div>
            ) : (
              <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                {translate("no-informations-for-available-places")}
              </div>
            )}
            {place.color !== "closed" && place.stand.available_electric_cars !== null && (
              <>
                <div className="lc-pr-realtime-elec-slots">{place.stand.available_electric_cars}</div>
              </>
            )}
            {place.color !== "closed" && place.stand.available_prm !== null && (
              <>
                <div className="lc-pr-realtime-pmr-slots">{place.stand.available_prm}</div>
              </>
            )}
          </div>
          {place.stand.available > 0 && (
            <div className="lc-pr-time">
              {place.stand.time ? translate(`predict-${place.stand.time}`) : formattedUpdatedAt}
            </div>
          )}
          {prDetails && (
            <div
              className="lc-pr-more-infos"
              role="button"
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                prMoreInfos();
              }}
              onKeyDown={(e) => handleKeyPress(prMoreInfos, e)}
            >
              {translate("more-informations")}
            </div>
          )}
        </>
      )}
    </PRPlaceInfo>
  );
};

// "poi_type:vcub"
export const UIVCub = (props) => {
  const { place, options, displayon } = props;
  const vcubDetails = options?.thematics?.find((t) => t.name === "vcub")?.details;
  const vcubMoreInfos = () => message({ clicked: "vcub-more-infos", id: place.id });

  if (!place.stand) {
    return null;
  }

  return (
    <VCubPlaceInfos displayon={displayon}>
      {displayon === "map" && (
        <>
          <div className="lc-bss" displayon={displayon} data-realtime-seats>
            <span className="lc-bikes">{place.stand.available_bikes}</span>
            {!place.stand.time && <span className="lc-electric-bikes">{place.stand.available_electric_bikes}</span>}
            <span className="lc-seats">{place.stand.available_places}</span>
          </div>
          <div className="lc-bss-time">
            {place.stand.time ? translate(`predict-${place.stand.time}`) : translate("data-update-time")}
          </div>
          {vcubDetails && (
            <div
              className="lc-vcub-more-infos"
              role="button"
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                vcubMoreInfos();
              }}
              onKeyDown={(e) => handleKeyPress(vcubMoreInfos, e)}
            >
              {translate("more-informations")}
            </div>
          )}
        </>
      )}
    </VCubPlaceInfos>
  );
};

// poi_type:amenity:velonecy
export const UIVelonecy = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <>
      <PlaceInfo>
        <PlaceInfoTitle>{translate("services-title")}</PlaceInfoTitle>
        <PlaceInfoContent>
          {place.bicycle_rental !== undefined && (
            <div>
              {translate("bicycle-rental", false)} :{" "}
              {place.bicycle_rental === true ? translate("yes", false) : translate("no", false)}
            </div>
          )}
          {place.pump !== undefined && (
            <div>
              {translate("pump", false)} : {place.pump === true ? translate("yes", false) : translate("no", false)}
            </div>
          )}
          {place.repair !== undefined && (
            <div>
              {translate("repair-station", false)} :{" "}
              {place.repair === true ? translate("yes", false) : translate("no", false)}
            </div>
          )}
        </PlaceInfoContent>
      </PlaceInfo>
    </>
  );
};

// "poi_type:amenity:bicycle_secure_parking"
export const UIBicycleSecureParking = (props) => {
  const { place } = props;

  if (!place) {
    return false;
  }

  return (
    <>
      <PlaceInfo>
        <PlaceInfoFlex>
          <PlaceInfoTitle tabIndex="0" aria-label={place.capacity + " " + translate("total-places", false)}>
            {translate("nb-places")}&nbsp;
          </PlaceInfoTitle>
          <PlaceInfoContent>{place.capacity}</PlaceInfoContent>
        </PlaceInfoFlex>
      </PlaceInfo>
      {place.prices && (
        <PlaceInfo>
          <PlaceInfoFlex>
            <PlaceInfoTitle
              tabIndex="0"
              aria-label={
                translate("prices", false) +
                " " +
                place.prices
                  .split(";")
                  .map((price) =>
                    price.replace("month", translate("month", false)).replace("year", translate("year", false))
                  )
                  .join(", ")
              }
            >
              {translate("prices")}&nbsp;
            </PlaceInfoTitle>
            <PlaceInfoContent>
              {place.prices
                .split(";")
                .map((price) =>
                  price.replace("month", translate("month", false)).replace("year", translate("year", false))
                )
                .join(", ")}
            </PlaceInfoContent>
          </PlaceInfoFlex>
        </PlaceInfo>
      )}
    </>
  );
};

// "poi_type:amenity:bicycle_bollard"
export const UIBicycleBollard = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfoFlex tabIndex="0" aria-label={place.capacity + " " + translate("total-places", false)}>
      <PlaceInfoTitle>{translate("nb-places")}&nbsp;</PlaceInfoTitle>
      <PlaceInfoContent>{place.capacity}</PlaceInfoContent>
    </PlaceInfoFlex>
  );
};

// "poi_type:amenity:bicycle_repair_station"
export const UIBicycleRepairStation = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle>{translate("services-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        <span>
          {translate("pump", false)} : {place.pump === true ? translate("yes", false) : translate("no", false)}
        </span>
        <br />
        <span>
          {translate("repair-station", false)} :{" "}
          {place.repair === true ? translate("yes", false) : translate("no", false)}
        </span>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

// "poi_type:amenity:private_bicycle_rental"
export const UIPrivateBicycleRental = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle>{translate("services-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        <span>
          {translate("repair-station", false)} :{" "}
          {place.repair === true ? translate("yes", false) : translate("no", false)}
        </span>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

// "poi_type:leisure:swimming_area"
export const UISwimmingArea = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle>{translate("services-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        {place.fee !== undefined && (
          <div>
            {translate("beach-fee", false)} :{" "}
            {place.fee === "no" ? translate("no", false) : place.fee === "yes" ? translate("yes", false) : place.fee}
          </div>
        )}
        {place.supervised !== undefined && (
          <div>
            {translate("beach-supervised", false)} :{" "}
            {place.supervised === "no" ? translate("no", false) : "yes" ? translate("yes", false) : place.supervised}
          </div>
        )}
        {place.bike_ride !== undefined && (
          <div>
            {translate("bike-ride", false)} :{" "}
            {place.bike_ride === "yes" ? translate("yes", false) : translate("no", false)}
          </div>
        )}
        {place.parking !== undefined && (
          <div>
            {translate("parking", false)} : {place.parking === "yes" ? translate("yes", false) : translate("no", false)}
          </div>
        )}
        {place.services !== undefined && (
          <div>
            {translate("other-services", false)} :&nbsp;
            {place.services
              .split(";")
              .map((s) => translate(s, false))
              .join(", ")}
          </div>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

// ["poi_type:amenity:car_rental", "poi_type:amenity:citiz"].includes(place.cat_id)
export const UICitiz = (props) => {
  const { place, displayon } = props;

  if (!place || (!place.stand && displayon === "map")) {
    return null;
  }

  const verbose = ["arras-artis"].includes(REACT_APP_PROJECT);

  if (verbose) {
    return (
      <>
        {(place.stand?.available_cars && place.stand?.available_cars !== 0) ||
        (place.stand?.available_electric_cars && place.stand?.available_electric_cars !== 0) ? (
          <>
            {place.stand?.available_cars !== 0 && (
              <PlaceInfo
                tabIndex="0"
                aria-label={`${place.stand?.available_cars} ${translate("cars-available", false)}`}
              >
                <PlaceInfoFlex>
                  <PlaceInfoTitle>{translate("nb-car-available")}&nbsp;</PlaceInfoTitle>
                  <PlaceInfoContent data-realtime-seats>
                    {place.stand?.available_cars}
                    <PictoBike
                      src={assetsPath("/assets/images/modes/citiz-car.svg")}
                      alt={translate("cars-available", false)}
                      aria-hidden="true"
                    />
                    <Tippy
                      theme={"latitude"}
                      touch={["hold", 500]}
                      placement={"right"}
                      boundary="window"
                      content={translate("realtime-gif-title")}
                    >
                      <RealtimeImage
                        src={assetsPath("/assets/images/realtime.gif")}
                        alt={translate("realtime-gif-alt", false)}
                        aria-hidden="true"
                        className="lc-realtime-gif"
                        marginLeft={true}
                      />
                    </Tippy>
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
            {place.stand?.available_electric_cars !== 0 && (
              <PlaceInfo
                tabIndex="0"
                aria-label={`${place.stand?.available_electric_cars} ${translate("electrics-cars-available", false)}`}
              >
                <PlaceInfoFlex>
                  <PlaceInfoTitle>{translate("nb-electric-car-available")}&nbsp;</PlaceInfoTitle>
                  <PlaceInfoContent data-realtime-seats>
                    {place.stand?.available_electric_cars}
                    <PictoBike
                      src={assetsPath("/assets/images/modes/citiz-electric-car.svg")}
                      alt={translate("electrics-cars-available", false)}
                      aria-hidden="true"
                    />
                    <Tippy
                      theme={"latitude"}
                      touch={["hold", 500]}
                      placement={"right"}
                      boundary="window"
                      content={translate("realtime-gif-title")}
                    >
                      <RealtimeImage
                        src={assetsPath("/assets/images/realtime.gif")}
                        alt={translate("realtime-gif-alt", false)}
                        aria-hidden="true"
                        className="lc-realtime-gif"
                        marginLeft={true}
                      />
                    </Tippy>
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
          </>
        ) : place.stand?.available_cars === 0 ? (
          <PlaceInfo>
            <PlaceInfoContent>
              <PlaceRealtimeInfo data-realtime-seats>{translate("no-available-car")}</PlaceRealtimeInfo>
            </PlaceInfoContent>
          </PlaceInfo>
        ) : (
          <PlaceInfo>
            <PlaceInfoContent>
              <PlaceRealtimeInfo>{translate("no-informations-for-available-cars")}</PlaceRealtimeInfo>
            </PlaceInfoContent>
          </PlaceInfo>
        )}
      </>
    );
  }

  // beatifull version :

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={
        place.stand
          ? `${place.stand?.available_cars ? place.stand?.available_cars : 0} ${translate("cars-available", false)}. ${
              place.stand.available_electric_cars ? place.stand.available_electric_cars : 0
            } ${translate("electrics-cars-available", false)}`
          : translate("no-informations-for-available-cars", false)
      }
    >
      <PlaceInfoTitle>{translate("nb-car-available")}</PlaceInfoTitle>
      <PlaceInfoContent>
        {(place.stand?.available_cars && place.stand?.available_cars !== 0) ||
        (place.stand?.available_electric_cars && place.stand?.available_electric_cars !== 0) ? (
          <PlaceCars data-realtime-seats>
            <PlaceCar>
              {place.stand?.available_cars}
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"right"}
                boundary="window"
                content={translate("car")}
              >
                <img
                  src={assetsPath("/assets/images/modes/citiz-car.svg")}
                  alt={translate("cars-available", false)}
                  aria-hidden="true"
                />
              </Tippy>
            </PlaceCar>
            <PlaceCar>
              {place.stand?.available_electric_cars}
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"right"}
                boundary="window"
                content={translate("electric-car")}
              >
                <img
                  src={assetsPath("/assets/images/modes/citiz-electric-car.svg")}
                  alt={translate("electrics-cars-available", false)}
                  aria-hidden="true"
                />
              </Tippy>
            </PlaceCar>
            <Tippy
              theme={"latitude"}
              touch={["hold", 500]}
              placement={"right"}
              boundary="window"
              content={translate("realtime-gif-title")}
            >
              <img
                src={assetsPath("/assets/images/realtime.gif")}
                alt={translate("realtime-gif-alt", false)}
                aria-hidden="true"
                className="lc-realtime-gif"
              />
            </Tippy>
          </PlaceCars>
        ) : place.stand?.available_cars === 0 ? (
          <PlaceRealtimeInfo data-realtime-seats>{translate("no-available-car")}</PlaceRealtimeInfo>
        ) : (
          <PlaceRealtimeInfo>{translate("no-informations-for-available-cars")}</PlaceRealtimeInfo>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UILoading = (props) => {
  const { place, displayon } = props;

  if (displayon === "map") {
    return null;
  }

  if (
    !place.stand &&
    ["poi_type:amenity:park_ride", "poi_type:amenity:parking"].includes(place.cat_id) &&
    !(place instanceof BikeInterface)
  ) {
    return (
      <div className="lc-loading" data-lc-loading>
        <img src={assetsPath("/assets/images/loading.gif")} width={20} alt={translate("back")} aria-hidden="true" />
      </div>
    );
  } else {
    return null;
  }
};

export const UIOpeningHours = (props) => {
  const { place, language } = props;

  if (!place.opening_hours) {
    return null;
  }

  return <PlaceInfoOpeningHours>{humanReadableOpeningHours(place.opening_hours, language)}</PlaceInfoOpeningHours>;
};

export const UIAddress = (props) => {
  const { place, displayon } = props;

  const showAddress =
    (place.address || place.city) &&
    ![
      "poi_type:p+r",
      "poi_type:alpin_skiing",
      "poi_type:cross_country_skiing",
      "poi_type:sled",
      "poi_type:snowshoeing",
    ].includes(place.cat_id);

  if (!showAddress || (place.cat_id === "poi_type:vcub" && displayon === "map")) {
    return null;
  }

  if (place.cat_id === "poi_type:vcub") {
    place.cp = null;
    place.city = null;
  }

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={translate("address-title", false) + " " + place.address + " " + place.cp + " " + place.city}
    >
      <PlaceInfoTitle>{translate("address-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        {place.address}
        {(place.cp || place.city) && (
          <>
            {place.address && <br />} {place.cp} {place.city}
          </>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIEmail = (props) => {
  const { place } = props;

  if (!place.email) {
    return null;
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate("mail-title") + " " + place.email}>
      <PlaceInfoTitle>{translate("mail-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        <a
          href={`mailto:${place.email}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {place.email}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIPhone = (props) => {
  const { place } = props;

  if (!place.phone) {
    return null;
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate("phone-title", false) + " " + place.phone}>
      <PlaceInfoTitle>{translate("phone-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        <a
          href={`tel:${place.phone}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        >
          {place.phone}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIWebsite = (props) => {
  const { place } = props;

  if (!place.website) {
    return null;
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate("website-title", false) + " " + place.website}>
      <PlaceInfoTitle>{translate("website-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        <a href={place.website} target="_blank" rel="noopener noreferrer">
          {place.website}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIWheelchair = (props) => {
  const { place } = props;

  if (!place.wheelchair) {
    return null;
  }

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={
        translate("wheelchair-title", false) +
        " " +
        (place.wheelchair === "yes"
          ? translate("wheelchair-yes", false)
          : place.place === "no"
          ? translate("wheelchair-no", false)
          : translate("wheelchair-limited", false))
      }
    >
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate("wheelchair-title")}</PlaceInfoTitle>
        <PlaceInfoContent>
          &nbsp;
          {place.wheelchair === "yes"
            ? translate("wheelchair-yes")
            : place.wheelchair === "no"
            ? translate("wheelchair-no")
            : translate("wheelchair-limited")}
        </PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  );
};

export const UIInfo = (props) => {
  const { place } = props;

  if (!place.info || ["poi_type:p+r", "poi_type:company"].includes(place.cat_id)) {
    return null;
  }

  return <PlaceInfoContent className="lc-info">{place.info}</PlaceInfoContent>;
};

export const UICustomText = (props) => {
  const { place } = props;

  if (!place || !place.displayCustomText) {
    return null;
  }

  if (place.cat_id === "poi_type:amenity:bicycle_rental" && place.stand) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoContent>
        <CustomText className="lc-display-custom-text">
          <img src={assetsPath("/assets/images/information.svg")} alt="information" />
          {translate(place.displayCustomText)}
        </CustomText>
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIDistributeur = (props) => {
  const { place } = props;

  if (!place && !place.buy_tickets && !place.reload_card && !place.solidarity_pricing) {
    return null;
  }

  if (REACT_APP_PROJECT === "bordeaux-tbm") {
    return (
      <>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate("title-buy-tickets", false) +
            " " +
            (place.buy_tickets ? translate("yes", false) : translate("no", false))
          }
        >
          <PlaceInfoInline>
            <PlaceInfoTitle>{translate("title-buy-tickets")}</PlaceInfoTitle>
            <PlaceInfoContent>&nbsp;{place.buy_tickets ? translate("yes") : translate("no")}</PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate("title-reload-card", false) +
            " " +
            (place.reload_card ? translate("yes", false) : translate("no", false))
          }
        >
          <PlaceInfoInline>
            <PlaceInfoTitle>{translate("title-reload-card")}</PlaceInfoTitle>
            <PlaceInfoContent>&nbsp;{place.reload_card ? translate("yes") : translate("no")}</PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate("title-solidarity-pricing", false) +
            " " +
            (place.solidarity_pricing ? translate("yes", false) : translate("no", false))
          }
        >
          <PlaceInfoInline>
            <PlaceInfoTitle>{translate("title-solidarity-pricing")}</PlaceInfoTitle>
            <PlaceInfoContent>&nbsp;{place.solidarity_pricing ? translate("yes") : translate("no")}</PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
      </>
    );
  } else {
    return null;
  }
};

export const UIToilet = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return <PlaceInfo>{place.pmr && <PlaceInfoTitle>{translate("aria-lines-stop-pmr")}</PlaceInfoTitle>}</PlaceInfo>;
};

export const UILocker = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return <></>;
};

export const UIMotorcycleParking = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate("total-places")}</PlaceInfoTitle>
        &nbsp;<PlaceInfoContent>{place.capacity}</PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  );
};

export const UIWaterPoint = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return <></>;
};

export const UIBAV = (props) => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate("bav-title", false) + " " + place.flow1 + " " + place.flow2}>
      <PlaceInfoTitle>{translate("bav-title")}</PlaceInfoTitle>
      <PlaceInfoContent>
        {place.flow1}
        <br />
        {place.flow2}
      </PlaceInfoContent>
    </PlaceInfo>
  );
};

export const UIHiking = (props) => {
  const { place, displayon } = props;

  if (!place) {
    return null;
  }

  return (
    <>
      {displayon !== "map" && (
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate("hiking-desc", false) +
            " " +
            place.desc
              .replace(/(<([^>]+)>)/gi, "")
              .replace("&lt;", /</g)
              .replace("&gt;", />/g)
              .replace("&#39;", /'/g)
              .replace("&#34;", /"/g)
              .replace("&#96;", /`/g)
              .replace("&#61;", /=/g)
              .replace("&amp;", /&/g)
          }
        >
          <PlaceInfoTitle>{translate("hiking-desc")}</PlaceInfoTitle>
          <PlaceInfoContent dangerouslySetInnerHTML={{ __html: place.desc }}></PlaceInfoContent>
        </PlaceInfo>
      )}
      <PlaceInfo>
        <PlaceInfoInline tabIndex="0" aria-label={translate("hiking-distance", false) + " " + place.distance}>
          <PlaceInfoTitle>{translate("hiking-distance")}</PlaceInfoTitle>
          <PlaceInfoContent> {place.distance}</PlaceInfoContent>
        </PlaceInfoInline>
        <PlaceInfoInline tabIndex="0" aria-label={translate("hiking-duration", false) + " " + place.duration}>
          <PlaceInfoTitle>{translate("hiking-duration")}</PlaceInfoTitle>
          <PlaceInfoContent> {place.duration}</PlaceInfoContent>
        </PlaceInfoInline>
        <PlaceInfoInline tabIndex="0" aria-label={translate("hiking-difficulty", false) + " " + place.difficulty}>
          <PlaceInfoTitle>{translate("hiking-difficulty")}</PlaceInfoTitle>
          <PlaceInfoContent> {place.difficulty}</PlaceInfoContent>
        </PlaceInfoInline>
      </PlaceInfo>
    </>
  );
};
