import { isSystemUS, translate } from "../services/tools";

/**
 * Comparator to use on a sort Array function
 * Ex : array.sort(compareValues('key', 'desc'))
 *
 * @param {String} key props to sort by
 * @param {String} order 'asc' or 'desc'
 */
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];
    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
}

/**
 * Split lines in groups of modes
 *
 * @param {Array} lines lines to group
 * @param {Array} types modes
 * @param {String} target property in line used to group
 * @param {Object} filter filter the mode Array created { key: "KEY_TO_FILTER", value: VALUE_TO_USE }
 */
export function groupLinesByMode(lines, modes, target = "mode", filter = null) {
  const groups = {};

  for (const mode of modes) {
    let linesInMode = lines.filter((l) =>
      Array.isArray(mode.modes)
        ? mode.modes.includes(l[target])
        : typeof l[target] === "string"
        ? l[target].includes(mode.modes)
        : mode.modes === l[target]
    );

    // Filter only the value from the specified key
    if (filter) {
      linesInMode = linesInMode.filter((line) => {
        if (typeof line[filter.key] === "string") {
          return line[filter.key].includes(filter.value);
        } else if (typeof line[filter.key] === "number") {
          return line[filter.key] === filter.value;
        } else {
          return line;
        }
      });
    }

    // We only keep groups with lines
    if (linesInMode.length > 0) {
      groups[mode.name] = linesInMode;
    }
  }

  // if we have group to concat
  for (const mode of modes) {
    if (mode.concat_with) {
      groups[mode.concat_with] = groups[mode.concat_with].concat(groups[mode.name]);
      delete groups[mode.name];
      groups[mode.concat_with].sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  }

  return groups;
}

/**
 * Return current Board bounding client rect
 */
export function getBoardBoundingClientRect() {
  return document.querySelector("[data-lc-board]").getBoundingClientRect();
}

export function navitiaDateToDate(navitiadate, seconds = false) {
  return new Date(
    navitiadate.substring(0, 4) +
      "-" +
      navitiadate.substring(4, 6) +
      "-" +
      navitiadate.substring(6, 8) +
      "T" +
      navitiadate.substring(9, 11) +
      ":" +
      navitiadate.substring(11, 13) +
      (seconds ? ":" + navitiadate.substring(13, 15) : ":00")
  );
}

/**
 * Return word with first letter uppercase
 */
const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() + (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

/**
 * Return date in format
 * format : y-m-d, ymd, d/m/y, ymdThm for navitia call
 */
export function formatDate(date, format, language, extend = false) {
  let month = date.getMonth() + 1;

  month = String(month < 10 ? "0" + month : month);
  let day = date.getDate();

  day = String(day < 10 ? "0" + day : day);
  let hour = date.getHours();

  hour = String(hour < 10 ? "0" + hour : hour);
  let minute = date.getMinutes();

  minute = String(minute < 10 ? "0" + minute : minute);

  switch (format) {
    case "ymd":
      return date.getFullYear() + month + day;
    case "y-m-d":
      return [date.getFullYear(), month, day].join("-");
    case "y/m/d":
      return [date.getFullYear(), month, day].join("/");
    case "m/d/y":
      return [month, day, date.getFullYear()].join("/");
    case "ymdhm":
    case "ymdThm":
      return date.getFullYear() + month + day + (format === "ymdThm" ? "T" : "") + hour + minute;
    case "d/m/y h:m":
      return [day, month, date.getFullYear()].join("/") + " " + hour + ":" + minute;
    case "h:m":
      const isToday = (date) => {
        const today = new Date();

        return (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        );
      };

      if (extend && !isToday(date)) {
        if (isSystemUS(language)) {
          return `${hour}:${minute} ${hour < 12 ? "am" : "pm"} (${capitalize(
            date.toLocaleString(language, { month: "long" })
          )} ${day})`;
        }

        return `${hour}:${minute} (${capitalize(
          date.toLocaleString(language, {
            weekday: "long",
          })
        )} ${day} ${capitalize(
          date.toLocaleString(language, {
            month: "long",
          })
        )})`;
      } else {
        return hour + ":" + minute;
      }

    case "h:m a":
      return (hour % 12) + ":" + minute + (hour < 12 ? " am" : " pm");
    case "full":
      if (isSystemUS(language)) {
        return date.toLocaleString(language, { month: "long" }) + " " + day;
      }

      return day + " " + date.toLocaleString(language, { month: "long" });
    case "full-with-time":
      if (isSystemUS(language)) {
        return (
          date.toLocaleString(language, { month: "long" }) +
          " " +
          day +
          " " +
          formatDate(date, "h:m a", language, extend)
        );
      }

      return (
        day + " " + date.toLocaleString(language, { month: "long" }) + " " + formatDate(date, "h:m", language, extend)
      );
    case "full-with-year":
      if (isSystemUS(language)) {
        return date.toLocaleString(language, { month: "long" }) + " " + day + ", " + date.getFullYear();
      }

      return day + " " + date.toLocaleString(language, { month: "long" }) + " " + date.getFullYear();
    default:
      return [day, month, date.getFullYear()].join("/");
  }
}

/**
 * Return date in libelle
 */
export function dateToLibelle(date, language, type = false) {
  const today = new Date();

  if (!date) {
    date = new Date();
  } else if (typeof date === "string" && date.includes("T") && date.length >= 13) {
    const splitted = date.split("T")[0];
    const time = date.split("T")[1];
    const stringed = splitted.slice(0, 4) + "-" + splitted.slice(4, 6) + "-" + splitted.slice(6, 8);

    date = new Date(stringed);
    date.setHours(time.substr(0, 2));
    date.setMinutes(time.substr(2, 2));
  }

  if (formatDate(today, "ymd") === formatDate(date, "ymd")) {
    return translate("calendar-today-word");
  } else {
    if (type) {
      return formatDate(date, type, language);
    } else {
      if (isSystemUS(language)) {
        return formatDate(date, "y-m-d");
      } else {
        return formatDate(date, "d/m/y");
      }
    }
  }
}

/**
 * Return date diif from now
 * date : a javascript date object
 * return an object with difference in seconds, minutes, hours and days
 */
export function dateFromNow(date) {
  const now = new Date();
  let diff = Math.abs(date - now);
  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return {
    seconds: seconds,
    minutes: minutes,
    hours: hours,
    days: days,
  };
}

/**
 * Display the time between date from now
 * @param {*} date
 */
export function displayDateFromNow(date, lessThanOneMinute = false) {
  const fromNow = dateFromNow(date);

  if (fromNow.days === 0 && fromNow.hours === 0 && fromNow.minutes === 0) {
    if (lessThanOneMinute) {
      return "< 1min";
    } else {
      return "1 min";
    }
  } else if (fromNow.days === 0 && fromNow.hours === 0 && fromNow.minutes > 0) {
    return fromNow.minutes + " min"; // on arrondi à la minute au dessus
  }

  /**
   * To do : complete with other case when hours > 0 and/or days > 0
   */
  if (fromNow.hours > 0) {
    if (fromNow.minutes === 0) {
      return `${fromNow.hours}h`;
    } else if (fromNow.minutes < 10) {
      return `${fromNow.hours}h0${fromNow.minutes}`;
    } else {
      return `${fromNow.hours}h${fromNow.minutes}`;
    }
  }

  return fromNow.minutes + " min";
}

export function getThematic(places = [], type, data, placesRef, clusterized) {
  let thematicPlaces = {};

  for (const place of places) {
    if (placesRef.find((p) => p.name === data)?.noClusters?.includes(place.cat_id)) {
      place.clusterized = false;
    } else {
      place.clusterized = clusterized;
    }

    if (type === "listByCat") {
      const toDisplay = placesRef.find((p) => p.name === data);

      if (toDisplay && toDisplay.places && toDisplay.places.includes(place.cat_id)) {
        if (place.cat_id) {
          (thematicPlaces[place.cat_id] = thematicPlaces[place.cat_id] || []).push(place);
        }
      }

      if (toDisplay && toDisplay.parentCategories) {
        for (const parentCategory of Object.keys(toDisplay.parentCategories)) {
          if (toDisplay.parentCategories[parentCategory].includes(place.cat_id)) {
            if (place.cat_id) {
              (thematicPlaces[place.cat_id] = thematicPlaces[place.cat_id] || []).push(place);

              if (thematicPlaces[parentCategory] === undefined) {
                thematicPlaces[parentCategory] = [];
              }
            }
          }
        }
      }
    } else if (type === "searchOnly") {
      (thematicPlaces[data] = thematicPlaces[data] || []).push(place);
    }
  }

  // Order list
  thematicPlaces = Object.keys(thematicPlaces)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .reduce((sorted, key) => {
      sorted[key] = thematicPlaces[key];
      return sorted;
    }, {});

  return thematicPlaces;
}
