import React from "react";
import Autocomplete from "@latitude-cartagene/react-autocomplete";
import { TimePicker } from "@latitude-cartagene/lc-time-picker";
import { luminance } from "luminance-js";
import history from "../history";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UICalendar from "../components/styled/UICalendar";
import {
  clickOnPlaceInList,
  focusInput,
  getCoordsFromUrlObject,
  getURLSearchParams,
  goToRouteCalculation,
  onChangeAutocompleteInput,
  onSelectAutocompleteValue,
  unique,
  onTabSelected,
  resize,
  assetsPath,
  handleKeyPress,
  translate,
  isActiveModule,
  addGetParam,
} from "./tools";
import Collapse from "@kunukn/react-collapse";
import { appStore } from "../store";
import {
  actionSetOpenedCollapse,
  actionSetRouteCalcDatesPanel,
  actionSetRouteCalcModesPanel,
  actionSetRouteCalcWalkingSpeedsPanel,
  actionSetRouteCalcBikePanel,
  actionSetAroundRadiusPanel,
  actionSetFavoriteLine,
  actionSetFavoriteStop,
  actionSetDisplayError,
  actionSetHideDisplayError,
} from "../actions/board";
import {
  actionHandleModesChanged,
  actionHandleWalkingSpeedsChanged,
  actionHandleBikeSpeedsChanged,
  actionHandlebikeProfilsChanged,
  actionHandleRepresentsChanged,
  actionSetCalendarDateTime,
  actionSetRadius,
  actionSetFavoritesData,
} from "../actions/app";
import {
  actionOutMarker,
  actionOverMarker,
  actionOpenMarker,
  actionBuildPlacesByCatInList,
  actionBuildPublicPlaces,
  actionBuildTransportPlaces,
} from "../actions/withRedux";
import Tippy from "@tippy.js/react";
import { fitBounds, renderLinesGroup } from "../utils/leaflet/map";
import { message } from "./message";
import { dateToLibelle } from "../utils/tools";
import { updateDataLayer } from "../tracking";
import UIPoiContent from "../components/styled/UIPoiContent";
import axios from "../middlewares/axios";

const { REACT_APP_LINES_MAIN_TYPE, REACT_APP_LINES_TYPE_EXCEPTIONS, REACT_APP_GO_TO_RC_URL } = process.env;

export const buildAutocomplete = (state, inputProps, isModal) => {
  const { pathname } = history.location;
  const { lock, size, modules, touchscreenSelected, linesModes, component } = state.app;
  const { options, searchIn } = component.props;
  const showGoToRC = options?.features?.["route-calculation"] === false ? false : true;
  let valueInput =
    inputProps === "inputStart"
      ? state.board.inputStartValue
      : inputProps === "inputEnd"
      ? state.board.inputEndValue
      : state.board.inputValue;

  const placeholder =
    inputProps === "inputSchedules"
      ? translate("placeholder-inputSchedules")
      : pathname.includes("/route-calculation")
      ? inputProps === "inputStart"
        ? translate("placeholder-inputStart")
        : translate("placeholder-inputEnd")
      : inputProps === "inputNetworkSearch"
      ? translate("placeholder-inputNetworkSearch")
      : inputProps.includes("inputThematic-") && pathname.includes("pt-vente")
      ? translate("placeholder-inputPtVente")
      : inputProps.includes("inputThematic-") && pathname.includes("e-tecely")
      ? translate("placeholder-inputeTecely")
      : inputProps.includes("inputThematic-") && pathname.includes("gab")
      ? translate("placeholder-inputGAB")
      : inputProps.includes("inputThematic-") && (pathname.includes("p+r") || pathname.includes("parcs-relais"))
      ? translate("placeholder-inputPR")
      : inputProps.includes("inputThematic-") && pathname.includes("park-ride")
      ? translate("placeholder-park-ride")
      : inputProps.includes("inputThematic-") && pathname.includes("agence")
      ? translate("placeholder-inputHideAgence")
      : inputProps.includes("inputThematic-") && pathname.includes("pt-service")
      ? translate("placeholder-inputHidePtService")
      : inputProps.includes("inputThematic-") && pathname.includes("vcub")
      ? translate("placeholder-inputVcub")
      : inputProps === "inputTourismPartners"
      ? translate("placeholder-inputTourismPartners")
      : inputProps.includes("inputThematic-") && pathname.includes("depositaires")
      ? translate("placeholder-inputDepositaires")
      : searchIn
      ? translate(`placeholder-input-${searchIn}`)
      : translate("placeholder-inputAround");

  return (
    <form
      className="lc-formAutocomplete"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Autocomplete
        inputProps={{ id: inputProps }}
        autoHighlight
        wrapperStyle={{
          display: "flex",
          flex: 1,
          position: "relative",
        }}
        value={valueInput}
        items={
          inputProps === "inputStart"
            ? state.board.inputStartItems
            : inputProps === "inputEnd"
            ? state.board.inputEndItems
            : state.board.inputItems
        }
        getItemValue={(item) => item.name}
        onSelect={(valueSelected, itemSelected) => {
          message({
            clicked: "autocomplete-item",
            id: itemSelected.id,
            type:
              itemSelected.id === "geoloc"
                ? "geolocation"
                : itemSelected.embedded_type
                ? itemSelected.embedded_type
                : itemSelected.cat_id
                ? itemSelected.cat_id
                : "line",
          });

          // Google Tag Manager
          if (isActiveModule("route-calculation")) {
            const type =
              itemSelected.id === "geoloc"
                ? "Géolocation"
                : itemSelected.id.includes("stop_area:")
                ? "Arrêt"
                : itemSelected.id.includes("admin:")
                ? "Commune"
                : itemSelected.id.includes("poi:")
                ? "Lieu"
                : "Adresse";

            updateDataLayer({
              event: "map-itinerarySearch",
              itinerarySearchType: type,
              itinerarySearchTerm: valueSelected,
              itinerarySearchInput: inputProps === "inputStart" ? "Départ" : "Arrivée",
              itinerarySearchHistoric: itemSelected.id.startsWith("history-"),
              itinerarySearchFavori: itemSelected.id.startsWith("favori-"),
            });
          } else if (itemSelected.id === "geoloc" && (component?.props?.moduleData?.title || component?.props?.title)) {
            updateDataLayer({
              event: "map-geolocationInput",
              module: translate(component?.props?.title || component?.props?.moduleData?.title, false),
            });
          }

          onSelectAutocompleteValue(valueSelected, itemSelected, inputProps, state, isModal);
        }}
        onChange={(event) => {
          onChangeAutocompleteInput(event, inputProps, state);
        }}
        onMenuVisibilityChange={(isOpen) => {
          // Edit the global overlow properties to avoid bad renders
          const margins = parseInt(getComputedStyle(document.querySelector(".lc-board")).margin) * 2 || 0;

          if (
            document.querySelector(".lc-scroll") &&
            window.innerHeight - margins > document.querySelector(".lc-board").getBoundingClientRect().height
          ) {
            document.querySelector(".lc-scroll").style.overflowY = isOpen ? "inherit" : "auto";
          }
        }}
        renderMenu={(children) => {
          const geoloc = children.filter((c) => c.key === "geoloc");
          const historyItems = children.filter((c) => c.key.includes("history-"));
          const favoritesItems = children.filter((c) => c.key.includes("favorite-"));
          const isEmpty = children.filter((c) => c.key === "no_result").length === 1;
          const partners = children.filter((c) => c.key.includes("partner-"));
          const partnersBy = {};

          if (partners.length >= 1) {
            const partnersModule = modules.find((m) => m.id === "tourism-partners" || m.id === "partners");

            const dataSetPer = partnersModule.options?.dataSetPer || {
              type: "type",
              subType: "region",
            };

            for (const partner of partners) {
              const value = partner.props[dataSetPer.subType];

              partnersBy[value] = partnersBy[value] || [];
              partnersBy[value].push(partner);
            }
          }

          if (isEmpty) {
            children = children.filter((c) => c.key !== "no_result");
          }

          return (
            <ul
              className={
                `lc-autocomplete lc-${size}` +
                (pathname.includes("route-calculation") ? " lc-offsetRoute" : "") +
                (valueInput ? " lc-offset-clear" : "")
              }
              id={"lc-autocomplete-" + inputProps}
              role="listbox"
              aria-label={
                translate("aria-autocomplete-input-" + inputProps, false)
                  ? translate("aria-autocomplete-input-" + inputProps, false)
                  : translate("aria-autocomplete-input", false, { key: "search", value: placeholder })
              }
            >
              {geoloc}
              {historyItems.length >= 1 && !touchscreenSelected && (
                <li className="lc-autocompleteHistory">
                  <div className="lc-autocompleteHeader">{translate("autocomplete-historic")}</div>
                  <ul>{historyItems}</ul>
                </li>
              )}
              {favoritesItems.length >= 1 && (
                <li className="autocompleteFavorites">
                  <div className="lc-autocompleteHeader">{translate("autocomplete-favorites")}</div>
                  <div>{favoritesItems}</div>
                </li>
              )}
              {partners.length >= 1 &&
                Object.keys(partnersBy)
                  .sort()
                  .map((value) => {
                    return (
                      <div className="lc-autocompleteFavorites" key={value}>
                        <div className="lc-autocompleteHeader">{value}</div>
                        <div>{partnersBy[value].sort((a, b) => a.props.children.localeCompare(b.props.children))}</div>
                      </div>
                    );
                  })}
              {isEmpty && (
                <li className="lc-item lc-no-result" key={"emptyResults"}>
                  {translate("autocomplete-no-result")}
                </li>
              )}
              {historyItems.length === 0 &&
                partners.length === 0 &&
                favoritesItems.length === 0 &&
                children.filter((c) => c.key !== "geoloc")}
            </ul>
          );
        }}
        renderInput={(props) => (
          <>
            <input
              className="lc-input"
              data-lc-input
              tabIndex="0"
              {...props}
              onFocus={(e) => !isModal && focusInput(e, inputProps, state)}
              placeholder={placeholder}
              aria-label={
                translate("aria-autocomplete-input-" + inputProps, false)
                  ? translate("aria-autocomplete-input-" + inputProps, false)
                  : translate("aria-autocomplete-input", false, { key: "search", value: placeholder })
              }
              aria-autocomplete="list"
              aria-controls={"lc-autocomplete-" + inputProps}
            />

            {(REACT_APP_GO_TO_RC_URL || modules.find((m) => m.id === "route-calculation" && m.hide !== true)) &&
              !isModal &&
              !lock &&
              state.board.goToValid &&
              !pathname.includes("/route-calculation") &&
              showGoToRC && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"top"}
                  boundary="window"
                  content={translate("title-go-to-route-calculation")}
                >
                  <div
                    className="lc-go-to-route lc-around"
                    tabIndex="0"
                    role="button"
                    onClick={async () => {
                      const params = getURLSearchParams(history.location);

                      if (pathname.includes("/lines")) {
                        goToRouteCalculation(params.stop_area);
                      } else if (isActiveModule("around") && !params.from.includes(";")) {
                        goToRouteCalculation(params.from);
                      } else if (params.place) {
                        goToRouteCalculation(params.place);
                      } else {
                        const coords = await getCoordsFromUrlObject(
                          getURLSearchParams(history.location).from,
                          null,
                          component
                        );

                        goToRouteCalculation({
                          address: {
                            lon: coords[1],
                            lat: coords[0],
                          },
                        });
                      }
                    }}
                    onKeyPress={async (e) =>
                      await handleKeyPress(e, async () => {
                        const params = getURLSearchParams(history.location);

                        if (pathname.includes("/lines")) {
                          goToRouteCalculation(params.stop_area);
                        } else if (isActiveModule("around") && !params.from.includes(";")) {
                          goToRouteCalculation(params.from);
                        } else if (params.place) {
                          goToRouteCalculation(params.place);
                        } else {
                          const coords = await getCoordsFromUrlObject(
                            getURLSearchParams(history.location).from,
                            null,
                            component
                          );

                          goToRouteCalculation({
                            address: {
                              lon: coords[1],
                              lat: coords[0],
                            },
                          });
                        }
                      })
                    }
                  >
                    <img
                      src={assetsPath("/assets/images/menu/route-calculation.svg")}
                      alt={translate("route-calculation-board-title")}
                    />
                  </div>
                </Tippy>
              )}
          </>
        )}
        renderItem={(item, isHighlighted) => {
          const lineMode = linesModes && linesModes.find((mode) => mode.modes.includes(item.mode));

          item.styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";

          if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);

            const foundExceptedLine = exceptions.find((e) =>
              e.lines.includes(item.id.replace(/history-|favorite-/g, ""))
            );

            if (foundExceptedLine) {
              item.styleLine = foundExceptedLine.type;
            }
          }

          const renderLineAutocomplete = () => {
            switch (item.styleLine) {
              case "modeWithDirection":
                return (
                  <div
                    key={item.id}
                    className="lc-line lc-line-with-direction lc-mode"
                    style={{
                      background: "#" + item.color,
                      color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {lineMode ? lineMode.name : ""}
                  </div>
                );
              case "codeWithDirection":
                return (
                  <div
                    key={item.id}
                    className="lc-line lc-line-with-direction lc-code"
                    style={{
                      background: "#" + item.color,
                      color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {item.code}
                  </div>
                );
              case "imageWithRouteDirection":
              case "image":
                return (
                  <div className="lc-line">
                    <img src={assetsPath("/assets/images/lines/" + item.code + ".svg")} alt={item.code} />
                  </div>
                );
              case "color":
                return (
                  <div className="lc-line">
                    <span
                      className={"lc-line-code"}
                      style={{
                        background: "#" + item.color,
                        color: luminance(item.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {item.code}
                    </span>
                  </div>
                );
              default:
                return "";
            }
          };

          return item.embedded_type ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img
                width={30}
                src={assetsPath("/assets/images/autocomplete/" + item.embedded_type + ".svg")}
                alt={item.embedded_type}
              />
              {item.name}
            </li>
          ) : item.isPartner ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={`partner-${item.id}`}
              region={item.region}
              type={item.type}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              {item.name}
            </li>
          ) : item.id === "geoloc" ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img
                width={30}
                src={assetsPath("/assets/images/autocomplete/position.svg")}
                alt={translate("autocomplete-geoloc")}
                aria-hidden="true"
              />
              {item.name}
            </li>
          ) : item.id.includes("history-") && item.type && item.type !== "line" ? (
            <div
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/" + item.type + ".svg")} alt={item.type} />
              {item.name}
            </div>
          ) : item.id.includes("favorite-") && item.type !== "line" && item.type !== "stop_point" ? (
            <div
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/" + item.type + ".svg")} alt={item.type} />
              {item.name}
            </div>
          ) : item.id.includes("stop_") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/stop_area.svg")} alt={translate("stop")} />
              {item.name}
            </li>
          ) : state.board.thematicPlaces ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/places/" + item.code + ".svg")} alt={item.code} />
              {item.name}
            </li>
          ) : item.id.includes("line") ? (
            <div
              className={"lc-autocomplete-line" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              style={{ padding: 10 }}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
            >
              {renderLineAutocomplete()}
              <div
                style={{
                  flex: 5,
                  paddingLeft: 10,
                }}
              >
                {item.code !== item.name || !item.routes ? (
                  <div className="lc-autocompleteDirection" key={item.name} aria-label={item.name.replace("\n", " à ")}>
                    {item.name.replace("\n", " <> ")}
                  </div>
                ) : (
                  unique(item.routes, "name").map((route) => (
                    <div
                      className="lc-autocompleteDirection"
                      key={route.name}
                      aria-label={route.name.replace("\n", " à ")}
                    >
                      {route.name.replace("\n", " <> ")}
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : item.id.includes("town") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/administrative_region.svg")} alt="town" />
              {item.name}
            </li>
          ) : item.id.includes("aeroway") ? (
            <li
              className={"lc-item" + (isHighlighted ? " lc-highlight" : "")}
              key={item.id}
              role="option"
              id={`result-item-${item.index}`}
              aria-selected={isHighlighted ? "true" : "false"}
              aria-label={item.name}
            >
              <img width={30} src={assetsPath("/assets/images/autocomplete/aeroway_terminal.svg")} alt="aeroway" />
              {item.name}
            </li>
          ) : (
            <div key="no_result" />
          );
        }}
      />
      {valueInput && (
        <Tippy
          theme={"latitude"}
          touch={["hold", 500]}
          placement={"top"}
          boundary="window"
          content={translate("autocomplete-clear-input")}
        >
          <span
            className="lc-autocompleteClear"
            onClick={() => {
              onChangeAutocompleteInput({ target: { value: "" } }, inputProps, state);
            }}
            onKeyPress={(e) =>
              handleKeyPress(e, () => {
                onChangeAutocompleteInput({ target: { value: "" } }, inputProps, state);
              })
            }
            role="button"
            tabIndex="0"
          />
        </Tippy>
      )}
    </form>
  );
};

export const buildPlacesByCatInList = (state, places = []) => {
  let placesCatToRender = Object.keys(places);
  const params = getURLSearchParams(history.location);
  const { openedCollapse, thematicPlaces } = state.board;
  const { component, placesRef } = state.app;
  let searchIn = null;
  let groupBy = null;
  let multiplePlacesCatToRender = placesCatToRender.length > 1;

  if (state?.app?.component?.state?.selectedFind) {
    searchIn = state.app.component.state.selectedFind;

    places[searchIn.cat_id] = [{ ...searchIn, id: searchIn.id + "-copy" }];
    placesCatToRender = placesCatToRender.filter((cat) => cat !== searchIn.cat_id);
  }

  if (!multiplePlacesCatToRender && state?.app?.component?.props?.moduleData?.groupBy) {
    groupBy = state.app.component.props.moduleData.groupBy;
    let groups = [];

    places[Object.keys(places)[0]].forEach((place) => {
      if (!groups.includes(place[groupBy])) {
        groups.push(place[groupBy]);
      }
    });
    placesCatToRender = groups;
  }

  if (placesCatToRender.length === 0) {
    return <div className="lc-empty">{translate("no-places-around")}</div>;
  }

  const handleToggleCollapseCat = (e, catToRender) => {
    message({ clicked: "thematic", id: catToRender });
    appStore.dispatch(actionSetOpenedCollapse(catToRender));
    const thematicData = component?.props?.moduleData?.data;
    const placeRefData = placesRef.find((p) => p.name === thematicData);

    if (thematicPlaces && thematicPlaces[catToRender]?.length === 1) {
      clickPlace(e, thematicPlaces[catToRender][0], state);
    }

    if (component?.props?.moduleData?.filterMarkersWithOpenedCollapse === true) {
      if (
        placeRefData &&
        placeRefData.parentCategories &&
        Object.keys(placeRefData.parentCategories).includes(catToRender) &&
        openedCollapse !== catToRender
      ) {
        appStore.dispatch(
          actionBuildTransportPlaces(
            placeRefData.parentCategories[catToRender].map((subCat) => [...thematicPlaces[subCat]]).flat()
          )
        );
      } else if (openedCollapse === catToRender) {
        appStore.dispatch(actionBuildTransportPlaces(thematicPlaces));
      } else {
        appStore.dispatch(actionBuildTransportPlaces(thematicPlaces[catToRender]));
      }
    }

    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  return placesCatToRender.map((catToRender) => {
    const thematicData = component?.props?.moduleData?.data;
    const placeRefData = placesRef.find((p) => p.name === thematicData);

    const isParentCat =
      placeRefData && placeRefData.parentCategories && Object.keys(placeRefData.parentCategories).includes(catToRender)
        ? true
        : false;

    if (
      placeRefData &&
      placeRefData.parentCategories &&
      Object.keys(placeRefData.parentCategories)
        .map((pc) => placeRefData.parentCategories[pc])
        .flat()
        .includes(catToRender)
    ) {
      return null;
    }

    return (
      <div
        key={catToRender}
        className={"lc-group" + (component.state.tab === 0 ? " lc-tab-transport" : " lc-tab-places")}
      >
        <div
          className="lc-group-name"
          onClick={(e) => {
            if (groupBy) {
              const markersToZoom = state.map.transportPlaces.filter((m) => m.props.place.city === catToRender);

              fitBounds(state.app.map, markersToZoom);
            }

            (multiplePlacesCatToRender || groupBy) &&
              handleToggleCollapseCat(
                e,
                isParentCat && placeRefData && placeRefData.parentCategories[catToRender].includes(openedCollapse)
                  ? false
                  : catToRender
              );
          }}
          onKeyPress={(e) => {
            if (groupBy) {
              const markersToZoom = state.map.transportPlaces.filter((m) => m.props.place.city === catToRender);

              fitBounds(state.app.map, markersToZoom);
            }

            (multiplePlacesCatToRender || groupBy) &&
              handleKeyPress(e, () =>
                handleToggleCollapseCat(
                  e,
                  isParentCat && placeRefData && placeRefData.parentCategories[catToRender].includes(openedCollapse)
                    ? false
                    : catToRender
                )
              );
          }}
          role="button"
          tabIndex="0"
          aria-label={translate(catToRender, false)}
        >
          <div className="lc-category">
            {!groupBy && (
              <img
                width={20}
                src={assetsPath(
                  "/assets/images/places/" + catToRender.replace("poi_type:", "").replace(":", "_") + ".svg"
                )}
                alt=""
                aria-hidden="true"
              />
            )}
            {groupBy ? catToRender : translate(catToRender)}
          </div>
          {(multiplePlacesCatToRender || groupBy) &&
            (state.board.thematicPlaces || (component.state.places && component.state.tab === 1)) && (
              <div className="lc-arrow-group">
                <img
                  className={
                    openedCollapse === catToRender ||
                    (isParentCat && placeRefData && placeRefData.parentCategories[catToRender].includes(openedCollapse))
                      ? ""
                      : "lc-closed"
                  }
                  src={assetsPath("/assets/images/v.svg")}
                  alt={translate("collapse-arrow")}
                />
              </div>
            )}
        </div>
        {isParentCat && multiplePlacesCatToRender ? (
          <Collapse
            isOpen={
              openedCollapse === catToRender ||
              (placeRefData && placeRefData.parentCategories[catToRender].includes(openedCollapse))
            }
          >
            {placeRefData.parentCategories[catToRender].map((subCatToRender) => (
              <div
                key={subCatToRender}
                className={
                  "lc-group-subcategory lc-group" + (component.state.tab === 0 ? " lc-tab-transport" : " lc-tab-places")
                }
                onClick={(e) =>
                  handleToggleCollapseCat(e, openedCollapse === subCatToRender ? catToRender : subCatToRender)
                }
                onKeyPress={(e) =>
                  handleKeyPress(e, () =>
                    handleToggleCollapseCat(e, openedCollapse === subCatToRender ? catToRender : subCatToRender)
                  )
                }
                role="button"
                tabIndex="0"
                aria-label={translate(subCatToRender, false)}
              >
                <div className="lc-group-name">
                  <div className="lc-category">
                    <img
                      width={20}
                      src={assetsPath(
                        "/assets/images/places/" + subCatToRender.replace("poi_type:", "").replace(":", "_") + ".svg"
                      )}
                      alt=""
                      aria-hidden="true"
                    />
                    {translate(subCatToRender)}
                  </div>
                  {(state.board.thematicPlaces || (component.state.places && component.state.tab === 1)) && (
                    <div className="lc-arrow-group">
                      <img
                        className={openedCollapse !== subCatToRender ? "lc-closed" : ""}
                        src={assetsPath("/assets/images/v.svg")}
                        alt={translate("collapse-arrow")}
                      />
                    </div>
                  )}
                </div>
                <Collapse isOpen={openedCollapse === subCatToRender}>
                  <ul className="lc-ul-places">{renderPlacesList(state, places[subCatToRender])}</ul>
                </Collapse>
              </div>
            ))}
          </Collapse>
        ) : !isParentCat &&
          (multiplePlacesCatToRender || groupBy) &&
          (state.board.thematicPlaces || (component.state.places && component.state.tab === 1)) ? (
          <Collapse
            isOpen={
              openedCollapse === catToRender ||
              (groupBy &&
                params.place &&
                catToRender === places[Object.keys(places)[0]].find((p) => p.id === params.place)[groupBy])
            }
          >
            <ul className="lc-ul-places">
              {renderPlacesList(
                state,
                groupBy ? places[Object.keys(places)[0]].filter((p) => p[groupBy] === catToRender) : places[catToRender]
              )}
            </ul>
          </Collapse>
        ) : (
          <ul className="lc-ul-places">{renderPlacesList(state, places[catToRender], groupBy)}</ul>
        )}
      </div>
    );
  });
};

export const buildAroundPreferences = (state) => {
  const { component, isMobile } = state.app;
  const { radiusPanel } = state.board;
  const { radius, currentRadius } = component.props;

  const prefs = (
    <>
      <div className="lc-around-radius-times" role="group" aria-labelledby="lc-around-radius-times">
        {radius
          ?.sort((a, b) => a.time > b.time)
          ?.map((r) => (
            <div
              className={"lc-radius-value" + (r.value === currentRadius ? " lc-active" : "")}
              key={r.time}
              onClick={(e) => {
                e.stopPropagation();
                appStore.dispatch(actionSetRadius(r.value));
                component.loadData();
              }}
            >
              <img
                src={assetsPath(`/assets/images/radius/${r.time}${r.value === currentRadius ? "-selected" : ""}.svg`)}
                alt={r.time}
              />
            </div>
          ))}
      </div>
    </>
  );

  return (
    <div className="lc-prefs lc-around">
      <div className="lc-buttons">
        <div
          className={radiusPanel ? "lc-collapse lc-radius lc-active" : "lc-collapse lc-radius"}
          id="lc-around-radius-times"
          onClick={() => {
            appStore.dispatch(actionSetAroundRadiusPanel(radiusPanel));
            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              appStore.dispatch(actionSetAroundRadiusPanel(radiusPanel));
              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={radiusPanel ? "true" : "false"}
          aria-controls="lc-radius"
        >
          {translate("around-radius-pref")}
          <span>
            {radius?.find((r) => r.value === currentRadius)?.time} {translate("time-around-tab-radius-unit")}
          </span>
          <div className={"lc-menu-item-arrow" + (radiusPanel ? " lc-active" : "")} />
        </div>
        <Collapse className="collapse-css-transition lc-radius" id="lc-radius" isOpen={radiusPanel}>
          {(state) => <div className={"lc-" + state}>{prefs}</div>}
        </Collapse>
      </div>
    </div>
  );
};

export const buildRouteCalcPreferences = (state) => {
  const { app } = state;
  const { calculateItineraryNow, isMobile, language, modes, datePicker, calendar } = state.app;
  const { walkingSpeeds, bikeSpeeds, bikeProfiles } = app;
  const { datesPanel, modesPanel, walkingSpeedsPanel, bikePanel } = state.board;
  const departure = modes.represents === "departure";
  const modesChoice = [];

  for (const mode of Object.keys(app.modes)) {
    if (typeof app.modes[mode] === "boolean") {
      modesChoice.push({
        id: mode,
        name: translate(`modes-${mode}`),
      });
    }
  }

  const dates = (
    <>
      <div className="lc-represents">
        <div className="lc-radio">
          <label className={departure ? "lc-radio-checked" : ""}>
            <input
              type="radio"
              value="departure"
              checked={departure}
              tabIndex="0"
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("departure"))}
            />
            {translate("route-calculation-board-preferences-departure")}
          </label>
        </div>
        <div className="lc-radio">
          <label className={!departure ? "lc-radio-checked" : ""}>
            <input
              type="radio"
              value="arrival"
              checked={!departure}
              tabIndex="0"
              onChange={() => appStore.dispatch(actionHandleRepresentsChanged("arrival"))}
            />
            {translate("route-calculation-board-preferences-arrival")}
          </label>
        </div>
      </div>
      <div className="lc-pickers">
        <UICalendar
          button={true}
          minDate={datePicker.minDate}
          maxDate={datePicker.maxDate}
          defaultDateTime={datePicker.defaultDateTime}
          followInput={true}
        />
        <div
          className="lc-time-picker-group"
          onClick={(e) => {
            document.querySelector(".lc-time-picker").click();
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              document.querySelector(".lc-time-picker").click();
            })
          }
          role="button"
          tabIndex="0"
          aria-label={translate("aria-route-calculation-time", false, {
            key: "hour",
            value: calendar.date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" }),
          })}
        >
          <div>
            <img src={assetsPath("/assets/images/route-calculation-time.svg")} alt={translate("time-alt", false)} />
          </div>
          <TimePicker
            style={{
              position: "relative",
            }}
            className="lc-time-picker"
            popupClassName="lc-time-picker-popup"
            defaultValue={new Date()}
            placement={"bottomLeft"}
            use12Hours={language === "en"}
            showSecond={false}
            minuteStep={5}
            allowEmpty={false}
            inputReadOnly
            value={calendar.date}
            onChange={(date) => {
              appStore.dispatch(actionSetCalendarDateTime(date.getHours(), date.getMinutes()));
            }}
            onClose={() => document.querySelector(".rc-time-picker-input").blur()}
            ariaLabelInput={translate("aria-time-picker-input")}
            ariaLabelSelectHours={translate("aria-time-picker-hours")}
            ariaLabelSelectMinutes={translate("aria-time-picker-minutes")}
          />
        </div>
      </div>
    </>
  );

  const prefs = (
    <>
      <div className="lc-route-calculation-modes" role="radiogroup" aria-labelledby="lc-route-calculation-modes">
        {modesChoice
          .filter((mode) => mode.id !== "pmr")
          .map((mode) => (
            <div
              key={mode.id}
              className={
                "lc-route-calculation-mode" +
                (modes[mode.id] ? " lc-active" : "") +
                (["bss"].includes(mode.id) && modes["pmr"] ? " lc-forbiden" : "")
              }
              onClick={() => {
                if (!(["bss"].includes(mode.id) && modes["pmr"])) {
                  appStore.dispatch(actionHandleModesChanged(mode));
                }
              }}
              onKeyPress={(e) =>
                handleKeyPress(e, () => {
                  if (!(["bss"].includes(mode.id) && modes["pmr"])) {
                    appStore.dispatch(actionHandleModesChanged(mode));
                  }
                })
              }
              role="radio"
              tabIndex="0"
              aria-checked={modes[mode.id] ? "true" : "false"}
              aria-label={mode.name}
            >
              <img src={assetsPath("/assets/images/modes/" + mode.id + ".svg")} alt="" aria-hidden="true" />
              {mode.name}
            </div>
          ))}
      </div>
    </>
  );

  const renderProfil = (type) => {
    return (
      <>
        <div
          className={
            (type === "bike" && bikePanel) || (type === "traveler" && walkingSpeedsPanel)
              ? "lc-collapse lc-profile lc-active"
              : "lc-collapse lc-profile"
          }
          onClick={() => {
            switch (type) {
              case "traveler":
                if (!walkingSpeedsPanel) {
                  updateDataLayer({
                    event: "map-openItineraryPanel",
                    panel: translate("route-calculation-walking-speed"),
                  });
                }

                appStore.dispatch(actionSetRouteCalcWalkingSpeedsPanel(walkingSpeedsPanel));

                break;
              case "bike":
                if (!bikePanel) {
                  updateDataLayer({
                    event: "map-openItineraryPanel",
                    panel: translate("route-calculation-bike-profile"),
                  });
                }

                appStore.dispatch(actionSetRouteCalcBikePanel(bikePanel));

                break;

              default:
                break;
            }

            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              switch (type) {
                case "traveler":
                  if (!walkingSpeedsPanel) {
                    updateDataLayer({
                      event: "map-openItineraryPanel",
                      panel: translate("route-calculation-walking-speed"),
                    });
                  }

                  appStore.dispatch(actionSetRouteCalcWalkingSpeedsPanel(walkingSpeedsPanel));

                  break;
                case "bike":
                  console.log("bite");

                  if (!bikePanel) {
                    updateDataLayer({
                      event: "map-openItineraryPanel",
                      panel: translate("route-calculation-bike-profile"),
                    });
                  }

                  appStore.dispatch(actionSetRouteCalcBikePanel(bikePanel));

                  break;

                default:
                  break;
              }

              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={
            (type === "traveler" && walkingSpeedsPanel) || (type === "bike" && bikePanel) ? "true" : "false"
          }
          aria-controls="lc-profile"
        >
          {translate(`route-calculation-${type === "traveler" ? "walking-speed" : "bike-profile"}`)}
          <span>
            {
              {
                bike: (
                  <div>
                    {bikeProfiles.find((bp) => bp.value === true) !== undefined &&
                      translate("route-calculation-bike-profile-" + bikeProfiles.find((bp) => bp.value === true).type) +
                        ","}
                    {bikeSpeeds.find((bs) => bs.value === true) !== undefined &&
                      translate("route-calculation-bike-speed-" + bikeSpeeds.find((bs) => bs.value === true).type)}
                  </div>
                ),
                traveler: (
                  <div>
                    {walkingSpeeds.find((ws) => ws.value === true) !== undefined
                      ? translate(
                          "route-calculation-walking-speed-" + walkingSpeeds.find((ws) => ws.value === true).type
                        )
                      : modesChoice
                          .filter((mode) => mode.id === "pmr")
                          .filter((mode) => modes[mode.id])
                          .map((mode) => mode.name)}
                  </div>
                ),
              }[type]
            }
          </span>
          <div className={"lc-menu-item-arrow" + (walkingSpeedsPanel ? " lc-active" : "")} />
        </div>
        <Collapse
          className="collapse-css-transition lc-profile"
          id="lc-profile"
          isOpen={type === "bike" ? bikePanel : walkingSpeedsPanel}
        >
          {(state) => <div className={"lc-" + state}>{renderProfilChoice(type)}</div>}
        </Collapse>
      </>
    );
  };

  const renderProfilChoice = (type) => {
    if (type === "traveler") {
      return (
        <div className="lc-route-calculation-profiles" role="group" aria-labelledby="lc-route-calculation-profiles">
          {modesChoice
            .filter((mode) => mode.id === "pmr")
            .concat(walkingSpeeds)
            .map((mode) => {
              if (mode.id === "pmr") {
                mode = {
                  ...mode,
                  type: "pmr",
                  speed: null,
                  value: false,
                };
              }

              return (
                <div
                  key={mode.type}
                  className={
                    mode.id
                      ? "lc-route-calculation-mode" + (modes[mode.id] ? " lc-active" : "")
                      : "lc-route-calculation-walking-speed" + (mode.value === true ? " lc-active" : "")
                  }
                  onClick={() =>
                    mode.id === "pmr"
                      ? appStore.dispatch(actionHandleModesChanged(mode))
                      : appStore.dispatch(actionHandleWalkingSpeedsChanged(mode.type))
                  }
                  onKeyPress={(e) =>
                    handleKeyPress(e, () =>
                      mode.id === "pmr"
                        ? appStore.dispatch(actionHandleModesChanged(mode))
                        : appStore.dispatch(actionHandleWalkingSpeedsChanged(mode.type))
                    )
                  }
                  role="checkbox"
                  tabIndex="0"
                  aria-checked={modes[mode.id] || mode.value ? "true" : "false"}
                  aria-label={mode.id ? mode.name : translate("route-calculation-walking-speed-" + mode.type)}
                >
                  <img
                    src={assetsPath(`/assets/images/modes/${mode.id ? mode.id : "walking-speed-" + mode.type}.svg`)}
                    alt={mode.type}
                    aria-hidden="true"
                  />
                  {mode.id ? mode.name : translate("route-calculation-walking-speed-" + mode.type)}
                </div>
              );
            })}
        </div>
      );
    } else if (type === "bike") {
      return (
        <>
          <div className="lc-route-calculation-profiles" role="group" aria-labelledby="lc-route-calculation-profiles">
            {bikeProfiles.map((p) => {
              return (
                <div
                  key={p.type}
                  className={"lc-route-calculation-bike-profile" + (p.value === true ? " lc-active" : "")}
                  onClick={() => appStore.dispatch(actionHandlebikeProfilsChanged(p.type))}
                  onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandlebikeProfilsChanged(p.type)))}
                  role="checkbox"
                  tabIndex="0"
                  aria-checked={p.value ? "true" : "false"}
                  aria-label={translate("route-calculation-bike-profile-" + p.type)}
                >
                  <img
                    src={assetsPath(`/assets/images/modes/${"bike-profile-" + p.type}.svg`)}
                    alt={p.type}
                    aria-hidden="true"
                  />
                  {translate("route-calculation-bike-profile-" + p.type)}
                </div>
              );
            })}
          </div>
          <div className="lc-route-calculation-profiles" role="group" aria-labelledby="lc-route-calculation-profiles">
            {bikeSpeeds.map((bs) => {
              return (
                <div
                  key={bs.type}
                  className={"lc-route-calculation-bike-speed" + (bs.value === true ? " lc-active" : "")}
                  onClick={() => appStore.dispatch(actionHandleBikeSpeedsChanged(bs.type))}
                  onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleBikeSpeedsChanged(bs.type)))}
                  role="checkbox"
                  tabIndex="0"
                  aria-checked={bs.value ? "true" : "false"}
                  aria-label={translate("route-calculation-bike-speed-" + bs.type)}
                >
                  <img
                    src={assetsPath(`/assets/images/modes/${"bike-speed-" + bs.type}.svg`)}
                    alt={bs.type}
                    aria-hidden="true"
                  />
                  {translate("route-calculation-bike-speed-" + bs.type)}
                </div>
              );
            })}
          </div>
        </>
      );
    }
  };

  const showTravelerProfil = walkingSpeeds.length > 0 || modesChoice.filter((m) => m.id === "pmr").length > 0;
  const showBikerProfil = bikeSpeeds.length > 0 || bikeProfiles.length > 0;

  return (
    <div className="lc-prefs">
      <div className="lc-buttons">
        <div
          className={datesPanel ? "lc-collapse lc-date lc-active" : "lc-collapse lc-date"}
          onClick={() => {
            appStore.dispatch(actionSetRouteCalcDatesPanel(datesPanel));
            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              appStore.dispatch(actionSetRouteCalcDatesPanel(datesPanel));
              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={datesPanel ? "true" : "false"}
          aria-controls="lc-date"
          aria-label={translate(
            "aria-route-calculation-datetime",
            false,
            { key: "type", value: departure ? translate("departure") : translate("arrival") },
            {
              key: "date",
              value: calculateItineraryNow
                ? translate("route-calculation-departure-now")
                : dateToLibelle(calendar.date, language, "full-with-year"),
            },
            { key: "hour", value: calendar.date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" }) }
          )}
        >
          {departure ? translate("departure") : translate("arrival")}{" "}
          <span>
            {calculateItineraryNow
              ? translate("route-calculation-departure-now")
              : dateToLibelle(calendar.date, language, "full-with-year") +
                " " +
                translate("route-calculation-departure-date-hours") +
                " " +
                calendar.date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" })}
          </span>
          <div className={"lc-menu-item-arrow" + (datesPanel ? " lc-active" : "")} />
        </div>
        <Collapse className="collapse-css-transition lc-date" id="lc-date" isOpen={datesPanel}>
          {(state) => <div className={"lc-" + state}>{dates}</div>}
        </Collapse>
        <div
          className={modesPanel ? "lc-collapse lc-modes lc-active" : "lc-collapse lc-modes"}
          id="lc-route-calculation-modes"
          onClick={() => {
            if (!modesPanel) {
              updateDataLayer({
                event: "map-openItineraryPanel",
                panel: translate("route-calculation-pref"),
              });
            }

            appStore.dispatch(actionSetRouteCalcModesPanel(modesPanel));
            resize(isMobile);
          }}
          onKeyPress={(e) =>
            handleKeyPress(e, () => {
              appStore.dispatch(actionSetRouteCalcModesPanel(modesPanel));
              resize(isMobile);
            })
          }
          role="button"
          tabIndex="0"
          aria-expanded={modesPanel ? "true" : "false"}
          aria-controls="lc-modes"
          aria-label={translate("aria-route-calculation-preferences", false, {
            key: "prefs",
            value: modesChoice
              .filter((mode) => mode.id !== "pmr")
              .filter((mode) => modes[mode.id])
              .map((mode) => mode.name)
              .join(", "),
          })}
        >
          {translate("route-calculation-pref")}
          <span>
            {modesChoice
              .filter((mode) => mode.id !== "pmr")
              .filter((mode) => modes[mode.id])
              .map((mode) => mode.name)
              .join(", ")}
          </span>
          <div className={"lc-menu-item-arrow" + (modesPanel ? " lc-active" : "")} />
        </div>
        <Collapse className="collapse-css-transition lc-modes" id="lc-modes" isOpen={modesPanel}>
          {(state) => <div className={"lc-" + state}>{prefs}</div>}
        </Collapse>
        {showBikerProfil && renderProfil("bike")}
        {showTravelerProfil && renderProfil("traveler")}
      </div>
    </div>
  );
};

export const buildTabsPanel = (state) => {
  const { component } = state.app;
  const { pois, places, tab, town } = component.state;
  const { moduleData, searchIn, linesModes, radius, currentRadius } = component.props;

  if (component.props.placeClicked && tab === 0) {
    const { openedCollapse, placeClicked } = component.props;

    if (placeClicked.cat_id === openedCollapse && !pois[placeClicked.cat_id]) {
      for (const i in pois) {
        if (searchIn && pois[i][placeClicked.cat_id] && placeClicked.code === searchIn) {
          // In search in case we don't display it in tabs so we put first lines mode in opened collapse
          appStore.dispatch(actionSetOpenedCollapse(linesModes[0].name));
        } else if (pois[i][placeClicked.cat_id]) {
          appStore.dispatch(actionSetOpenedCollapse(i));
        }
      }
    }
  }

  if (!moduleData) {
    if (tab === 0) {
      appStore.dispatch(actionBuildTransportPlaces(pois));
      appStore.dispatch(actionBuildPublicPlaces([]));
    } else if (tab === 1) {
      appStore.dispatch(actionBuildTransportPlaces([]));
      appStore.dispatch(actionBuildPublicPlaces(places));
    }
  }

  return (
    <>
      <Tabs
        selectedIndex={component.state.tab || 0}
        onSelect={(index) => onTabSelected(component, index)}
        selectedTabClassName="lc-active"
        selectedTabPanelClassName="lc-active lc-scroll"
        className="lc-react-tabs"
      >
        <TabList className="lc-tab-list">
          <Tab className="lc-tab">
            {translate("transport-tab")}
            <br />
            <span>
              {town
                ? town.name.replace(/arrondissement/i, "")
                : isActiveModule("around") && radius?.length
                ? `${radius?.find((r) => r.value === currentRadius)?.time} ${translate("time-around-tab-radius-unit")}`
                : translate("time-around-tab")}
            </span>
          </Tab>
          <Tab className="lc-tab">
            {translate("places-tab")}
            <br />
            <span>
              {town
                ? town.name.replace(/arrondissement/i, "")
                : isActiveModule("around") && radius?.length
                ? `${radius?.find((r) => r.value === currentRadius)?.time} ${translate("time-around-tab-radius-unit")}`
                : translate("time-around-tab")}
            </span>
          </Tab>
        </TabList>
        <TabPanel className="lc-tab-panel" data-lc-scroll={component.state.tab === 0 ? "scroll" : ""}>
          {renderLinesGroup(component)}
        </TabPanel>
        <TabPanel className="lc-tab-panel" data-lc-scroll={component.state.tab === 1 ? "scroll" : ""}>
          {appStore.dispatch(actionBuildPlacesByCatInList(component.state.places))}
        </TabPanel>
      </Tabs>
    </>
  );
};

const clickPlace = (e, place, state) => {
  e.stopPropagation();

  const { pathname, search } = history.location;
  const { openedCollapse, thematicPlaces } = state.board;
  const { component } = state.app;
  const pois = component?.state?.pois || null;

  message({ clicked: "thematic-item", id: place.id });

  if (!component?.state?.lastestSelectedPlace || component?.state?.lastestSelectedPlace !== place.id) {
    updateDataLayer({
      event: "map-selectedPoi",
      name: place.name,
      category: place.cat,
      module: translate(component?.props?.title ? component?.props?.title : component?.props?.moduleData?.title, false),
    });

    if (component.state) {
      component.setState({ lastestSelectedPlace: place.id });
    }
  }

  if (isActiveModule("around")) {
    if (["poi_type:stations"].includes(place.cat_id)) {
      clickOnPlaceInList(place, pois, thematicPlaces);
    } else {
      openedCollapse !== place.cat_id && appStore.dispatch(actionSetOpenedCollapse(place.cat_id));
      appStore.dispatch(actionOpenMarker(place));
    }
  } else if (isActiveModule("multimobilities")) {
    if (["poi_type:stations", "poi_type:amenity:citiz", "poi_type:amenity:parking"].includes(place.cat_id)) {
      appStore.dispatch(actionOpenMarker(place));
      clickOnPlaceInList(place, pois, thematicPlaces);
    } else {
      const params = getURLSearchParams(history.location);

      delete params.id; // if id of bike is previously selected
      const searchParam = addGetParam(params, { place: place.id });

      delete searchParam.id;

      history.push({
        ...history.location,
        search: searchParam,
      });
      setTimeout(() => {
        openedCollapse !== place.cat_id && appStore.dispatch(actionSetOpenedCollapse(place.cat_id));
        appStore.dispatch(actionOpenMarker(place));
      });
    }
  } else {
    if (place.coord) {
      history.push({
        pathname,
        search: !decodeURIComponent(search).includes(place.id) ? "?place=" + place.id : "",
      });
    } else {
      component.setState({ displayInformations: place.id });
    }
  }
};

/**
 * Store user's favorites data
 * @param {String} token User token
 * @param {String} clientId User client id
 * @returns {Promise}
 */
export const getFavoritesData = async (token, clientId) => {
  const isMobile = appStore.getState().app.isMobile;

  if (token) {
    try {
      const response = await axios.get(`/api/favorites?token=${token}${clientId ? "&client_id=" + clientId : ""}`);

      appStore.dispatch(actionSetFavoritesData(response.data));

      return response.data;
    } catch (e) {
      appStore.dispatch(actionSetDisplayError("cant-get-favs", "line", translate("error-cant-get-favs")));
      resize(isMobile);
      setTimeout(() => {
        appStore.dispatch(actionSetHideDisplayError("cant-get-favs"));
        resize(isMobile);
      }, 5000);
      console.warn(e);
      return null;
    }
  }

  return null;
};

/**
 * Check if the given data is in the user's favorites
 * @param data
 * @param token
 * @returns {Promise<null>}
 */
export const isFavorite = async (data, token, clientId = null) => {
  if (token) {
    try {
      let favorites;
      const favoritesData = appStore.getState().app.favoritesData;

      if (favoritesData) {
        favorites = favoritesData;
      } else {
        favorites = await getFavoritesData(token, clientId);
      }

      if (favorites) {
        if (data.id.startsWith("stop_")) {
          return favorites.find((f) => f.id === data?.stop_area && f.route === data?.route_id);
        } else {
          return favorites.find((f) => f.id === data.id);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  return null;
};

export const updateFavorite = async (data, token, clientId = null) => {
  const isMobile = appStore.getState().app.isMobile;

  if (token) {
    const favorite = await isFavorite(data, token, clientId);
    let type = null;

    if (data.id.startsWith("line:")) {
      type = "line";
    } else if (data.id.startsWith("stop_")) {
      type = "stop";
    } else {
      console.warn("to implement");
    }

    const params = {
      token,
      client_id: clientId,
      id: data.id.startsWith("stop_point") ? data.stop_area : data.id,
      type: type,
      name: data.name,
    };

    if (data.id.startsWith("stop_")) {
      const p = getURLSearchParams(history.location);
      const line = p.line || p.current || null;

      params.route_id = data.route_id;
      params.ligne_id = line ? line.replace(/[_f|_b]([^_]*?)$/gim, "") : "";
      params.suite_type = "line";
    }

    if (!favorite && type) {
      params.id = data.id.startsWith("stop_point") ? data.stop_area : data.id;

      // add item into favorites
      try {
        await axios({
          method: "post",
          url: "/api/favorites",
          params,
        });

        // add item in favorite store
        switch (type) {
          case "line":
            appStore.dispatch(actionSetFavoriteLine(data));
            break;
          case "stop":
            appStore.dispatch(
              actionSetFavoriteStop({
                id: data.id.startsWith("stop_point") ? data.stop_area : data.id,
                type: type,
                name: data.name,
                route: data.id.startsWith("stop_point") ? data.route_id : null,
              })
            );
            break;
          default:
            console.warn(`${type} to implement`);
            break;
        }

        data.favorite = true;
      } catch (e) {
        appStore.dispatch(actionSetDisplayError(`add-fav-${data.id}`, type, translate("error-add-fav")));
        resize(isMobile);
        setTimeout(() => {
          appStore.dispatch(actionSetHideDisplayError(`add-fav-${data.id}`));
          resize(isMobile);
        }, 5000);
        const error = e.response && e.response.data ? e.response.data.id : e;

        console.warn(error);
      }
    } else {
      if (favorite?.stop_id) {
        params.id = favorite.stop_id;
      }

      // remove item from favorites
      try {
        await axios({
          method: "delete",
          url: "/api/favorites",
          params,
        });

        // remove item from favorite store
        switch (type) {
          case "line":
            appStore.dispatch(actionSetFavoriteLine(null));
            break;
          case "stop":
            appStore.dispatch(actionSetFavoriteStop(null));
            break;
          default:
            break;
        }

        data.favorite = false;
      } catch (e) {
        appStore.dispatch(actionSetDisplayError(`delete-fav-${data.id}`, type, translate("error-delete-fav")));
        resize(isMobile);
        setTimeout(() => {
          appStore.dispatch(actionSetHideDisplayError(`delete-fav-${data.id}`));
          resize(isMobile);
        }, 5000);
        const error = e.response && e.response.data ? e.response.data.id : e;

        console.warn(error);
      }
    }

    // Refresh stored favorites if needed
    const favoritesData = appStore.getState().app.favoritesData;

    if (favoritesData) {
      await getFavoritesData(token, clientId);
    }
  }

  return "No token";
};

export const renderPlacesList = (state, places) => {
  const { component } = state.app;
  const { placeClicked } = state.board;
  const displayInformations = component?.state?.displayInformations;
  const { options } = component.props;

  return places
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((place) => (
      <li
        key={place.id}
        className="lc-place"
        onClick={(e) => {
          clickPlace(e, place, state);
        }}
        onKeyPress={(e) => handleKeyPress(e, () => clickPlace(e, place, state))}
        role="button"
        tabIndex="0"
        onMouseEnter={() => appStore.dispatch(actionOverMarker(place))}
        onMouseLeave={() => setTimeout(() => appStore.dispatch(actionOutMarker(place)))}
        aria-label={place.name}
        aria-expanded={placeClicked && placeClicked.id === place.id ? "true" : "false"}
        aria-controls={"place-" + place.id}
      >
        <div
          className={"lc-place-content" + (placeClicked && place && placeClicked.id === place.id ? " lc-selected" : "")}
        >
          <span
            className={
              "lc-place-content-name" + (placeClicked && place && placeClicked.id === place.id ? " lc-selected" : "")
            }
          >
            {place.name}
          </span>
          {displayInformations === place.id && (
            <span className="lc-informations-display" dangerouslySetInnerHTML={{ __html: place.informations }} />
          )}
          {placeClicked && placeClicked.error && (
            <div className={"lc-" + placeClicked.error.severity}>
              <img src={assetsPath("/assets/images/error.svg")} alt={translate("severity-error")} />
              {placeClicked.error.message}
            </div>
          )}
          {placeClicked && !placeClicked.error && placeClicked.id === place.id && (
            <UIPoiContent place={placeClicked} displayon="board" options={options} />
          )}
        </div>
      </li>
    ));
};
